import React, { useState, useReducer,useEffect} from "react";
import {
  getAllStreets,
  createNewStreet,
  deleteAStreet,
  updateAStreet,
} from "services/streetService";
import {
  getAllAreas,createNewArea
} from "services/areaService";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';

const useStreetCRUD = () => {
  const { t } = useTranslation();
  const [showCreateStreetModal, setShowCreateStreetModal] = useState(false);
  const [showUpdateStreetModal, setShowUpdateStreetModal] = useState(false);
  const [clickLoading, setClickLoading] = useState(false);
  const [areas,setAreas] = useState([]);
  const [streets, setStreets] = useState([]);
  const [area, setArea] = useState('');

  const handleCreateModalClose = () => {
    setShowCreateStreetModal(false);
    setArea("");
    setClickLoading(false);
  }
  const handleUpdateModalClose = () => {
    setShowUpdateStreetModal(false);
    setArea("");
    setClickLoading(false);
  }
  const initialStreetState = {
    area: {name:"",id:""},
    street: "",
    streetId:""
  };
  const getAreas = async()=>{
   const response = await getAllAreas();
    if(response?.data?.data) {
      setAreas(response?.data?.data);
    }
  } 
  const reducer = (state,action) =>{
    switch (action.type) {
      case "SET_INPUT":
        return {
          ...state,
          [action.field]:action.payload
        }
      case "RESET":
        return {
          ...initialStreetState
        }
      default: 
        return state;
    }
  }
  const updatedReducer = (state,action) =>{
    switch (action.type) {
      case "SET_INPUT":
        return {
          ...state,
          [action.field]:action.payload
        }
      case "RESET":
        return {
          ...initialStreetState
        }
      default: 
        return state;
    }
  }
  const [streetState, dispatch] = useReducer(reducer, initialStreetState);
  const [updatedStreetState, dispatchUpdatedState] = useReducer(updatedReducer, initialStreetState);
  useEffect( ()=>{ 
      fetchData();
      getAreas();
  },[])
  const handleNewStreetNameChange = (e) =>{
    dispatch({
      type: "SET_INPUT",
      field:e.target.name,
      payload:e.target.value
    }) 
  }
  const handleUpdatedStreetNameChange = (e) =>{
    dispatchUpdatedState({
      type: "SET_INPUT",
      field:e.target.name,
      payload:e.target.value
    }) 
  }
  const onChangeArea = (area) =>{
    dispatch({
      type: "SET_INPUT",
      field:'area',
      payload:area
    })
  }
  const onChangeUpdatedArea = (area) =>{
    dispatchUpdatedState({
      type: "SET_INPUT",
      field:'area',
      payload:area
    })
  }
  const onOpenCreateModal = () => {
    setShowCreateStreetModal(true);
    setClickLoading(false);
  };
  const onOpenUpdateModal = (street) => {
    setClickLoading(false);
    dispatchUpdatedState({
      type: "SET_INPUT",
      field:'street',
      payload:street?.name
    }) 
    dispatchUpdatedState({
      type: "SET_INPUT",
      field:'area',
      payload:{name:street?.area?.name,id:street?.area?.id}
    }) 
    dispatchUpdatedState({
      type: "SET_INPUT",
      field:'streetId',
      payload:street?.id
    }) 
    setShowUpdateStreetModal(true);
  };
  const addStreet = async () => {
    setClickLoading(true);
    const response = await createNewStreet(streetState?.street,streetState?.area?.id);
    if(response.data.success){
        toast.success(t('ProcesSucceeded'), {
            position: 'top-left',
            autoClose: true,
            icon: "✅"
        });
        handleCreateModalClose();
        fetchData();
        dispatch({type: "RESET"})
    }
    else{
        toast.error(t('ProcessFailed'), {
            position: 'top-left',
            autoClose: true,
            progress:0.2
        });
    }
   
  };
  const sendUpdateRequest = async () => {
    setClickLoading(true);
    const response = await updateAStreet(updatedStreetState?.streetId,updatedStreetState?.street,updatedStreetState?.area?.id);
    if(response?.data?.success){
        toast.success(t('ProcesSucceeded'), {
            position: 'top-left',
            autoClose: true,
            icon: "✅"
        });
        handleUpdateModalClose();
        fetchData();
        dispatchUpdatedState({type: "RESET"})
    }
    else{
        toast.error(t('ProcessFailed'), {
            position: 'top-left',
            autoClose: true,
            progress:0.2
        });
    }
  };
  const deleteHandler = async (id) => {
    const response = await deleteAStreet(id);
    fetchData();
  };

  const fetchData = async () => {
    const response = await getAllStreets();
    if (response.status === 200) {
      setStreets(response?.data?.data);
    }
  };
  const addArea = async (updated=false) => {
    setClickLoading(true);
    const response = await createNewArea(area);
    if(response?.data?.success){
        toast.success(t('ProcesSucceeded'), {
            position: 'top-left',
            autoClose: true,
            icon: "✅"
        });
        if(updated){
          dispatchUpdatedState({
            type: "SET_INPUT",
            field:'area',
            payload:{name:response?.data?.data?.name,id:response?.data?.data?.id}
          })
        }
        else{
          dispatch({
            type: "SET_INPUT",
            field:'area',
            payload:{name:response?.data?.data?.name,id:response?.data?.data?.id}
          })
        }
        setArea("");
        setClickLoading(false);
        getAreas();
    }
    else{
        toast.error(t('ProcessFailed'), {
            position: 'top-left',
            autoClose: true,
            progress:0.2
        });
    }
  };
  return {
    streets,
    onOpenUpdateModal,
    deleteHandler,
    onOpenCreateModal,
    showCreateStreetModal,
    handleCreateModalClose,
    showUpdateStreetModal,
    handleUpdateModalClose,
    sendUpdateRequest,
    addStreet,
    streetState,
    areas,
    handleNewStreetNameChange,
    onChangeArea,
    setArea, 
    area,
    addArea,
    onChangeUpdatedArea,
    handleUpdatedStreetNameChange,
    updatedStreetState,
    clickLoading
  };
};
export default useStreetCRUD;
