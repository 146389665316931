import { createContext, useState, useMemo} from "react";

export const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({  token : '', isLogged : localStorage.getItem("isUserLogged")==="true" });
    const value = useMemo(() => ({ auth, setAuth }), [auth, setAuth]);
    return (
      <AuthContext.Provider value={value}>
        {children}
      </AuthContext.Provider>
    );
}
export default AuthProvider;