import { useState, useEffect, useReducer} from 'react';
import { getAllStreets, createNewStreet} from "services/streetService";
import { getAllAreas } from "services/areaService";
import { toast } from 'react-toastify';
import { arabicName, englishName, nationalIdRegex, emailRegex, phoneRegex } from "utils/regex";

const useGeneralInfo = (t, generalInfoState, setError, handleGeneralInfo) =>{
    //states 
    const [streets,setStreets] = useState([]);
    const [street,setStreet] = useState({name: "",area_id:NaN});
    const [disabledAddStreet , setDisabledAddStreet] = useState(false);
    const [areas,setAreas] = useState([]);

    //methods 
    const fetchStreets = async () => {
      const response = await getAllStreets();
      if (response.status === 200) {
        setStreets(response?.data?.data);
      }
    };
    const fetchAreas = async () => {
      const response = await getAllAreas();
      if (response.status === 200) {
        setAreas(response?.data?.data);
      }
    };
    const addStreet = async () => {
      const response = await createNewStreet(street.name,street.area_id);
      if(response.data.success){
          toast.success(t('ProcesSucceeded'), {
              position: 'top-left',
              autoClose: true,
              icon: "✅"
          });
          handleGeneralInfo('street_id', response.data.data.id)
          fetchStreets();
          setStreet({name: "",area_id:NaN});
          setDisabledAddStreet(true);
      }
      else{
          toast.error(response.data.message || t('ProcessFailed'), {
              position: 'top-left',
              autoClose: true,
              progress:0.2
          });
      }
    };
    const validateFullName = (fullName) => {
      dispatchError({
          type: "SET_INPUT",
          field:'fullName',
          payload:(fullName.length < 5 || (!arabicName.test(fullName) && !englishName.test(fullName)))
        });
    };
    const validateNationalId =(nationalId)=>{
      dispatchError({
          type: "SET_INPUT",
          field:'nationalId',
          payload:(nationalId && (nationalId.length !==14 || !nationalIdRegex.test(nationalId)) )
        });
    }
    const validateEmail =(email) => {
      dispatchError({
          type: "SET_INPUT",
          field:'email',
          payload: (email && email!=="" ) ?(email.length < 5 || !emailRegex.test(email)):false
        });
    }
    const handlePhoneError = (hasError) =>{
      dispatchError({
        type: "SET_INPUT",
        field:'phone',
        payload: hasError
      });
    }

  //constants
  const genderOptions = [
      {name:t('addPerson.gender'),value:""},
      {name:t('addPerson.male'),value:"ذكر"},
      {name:t('addPerson.female'),value:"أنثي"}
  ]
  const streetOptions = [{id:NaN,name:t('Choose')}, ...streets];
  const areasOptions = [{id:NaN,name:t('Choose')}, ...areas];
  const initialErrorFields = {
      fullName: {hasError : false , errorMessage : [t('validation.name1'),t('validation.name2')] },
      nationalId: {hasError : false , errorMessage : [t('validation')] },
      email : {hasError : false , errorMessage : [t('validation')] },
      phone : {hasError : false , errorMessage : [t('validation')] }
  };
  //reducers
  const reducerError = (state,action) => {
    const tempField = {...state};
    tempField[action.field].hasError = action.payload;
    switch (action.type) {
      case "SET_INPUT":
        return {
          ...tempField
        }
      case "RESET":
        return {
          ...initialErrorFields
        }
      default: 
      return state;
    }
  }
  const [errorFieldsState, dispatchError] = useReducer(reducerError, initialErrorFields);

  //Effects
  useEffect(() => {
    fetchStreets();
    fetchAreas();
   }, [])
  useEffect(() => { 
    validateFullName(generalInfoState.full_name);
  }, [generalInfoState.full_name]);
  useEffect(() => { 
    validateNationalId(generalInfoState.national_id);
  }, [generalInfoState.national_id]);
  useEffect(() => { 
    validateEmail(generalInfoState.email);
  }, [generalInfoState.email]);
  useEffect(() => { 
    const hasError = (errorFieldsState?.fullName?.hasError || errorFieldsState?.nationalId?.hasError || errorFieldsState?.email?.hasError || errorFieldsState?.phone?.hasError)
    setError(hasError);
  },[errorFieldsState]);
  return { 
    genderOptions, generalInfoState, handleGeneralInfo, 
    streetOptions, street, setStreet, addStreet, disabledAddStreet,
    areasOptions, errorFieldsState, handlePhoneError
  }
}
export default useGeneralInfo;