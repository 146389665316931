import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {getAllPeople, deleteAPerson} from "services/peopleService";

const useGetAllPeople = () => {
  const [people, setPeople] = useState([]);
  const [loading,setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);
  const deleteHandler = async (id) => {
    const response = await deleteAPerson(id);
    fetchData();
  };

  const fetchData = async () => {
    setLoading(true);
    const response = await getAllPeople();
    setLoading(false);
    if (response.status === 200) {
      setPeople(response.data.data);
    }
  };

  return { people, deleteHandler, loading };
};
export default useGetAllPeople;
