import { Col, Row, Card, Form, Button, InputGroup} from "@themesberg/react-bootstrap";
import { useTranslation } from 'react-i18next';
import Datetime from "react-datetime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import STGSingleSelect from "components/STGSingleSelect";
import useAdditionalInfo from "./hooks/useAdditionalInfo";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
import STGGetPostEntity from "components/STGGetPostEntity";

const AdditionalInfo = ({additionalInfoState, handleAdditionalInfo}) => {
    const { t } = useTranslation();
    const {
        maritalStatusOptions, 
        regularityOptions, yesOrNoOptions, churchName,
        setChurchName, addChurch, disabledAddChurch, jobName,
        setJobName, addJob, disabledAddjob, qualificationName,
        setQualificationName, disabledAddQualification, addQualification, 
        study, setStudy, addٍStudy, disabledAddStudy, churchOptions, jobOptions, qualificationOptions,
        studyOptions
    } = useAdditionalInfo(t, handleAdditionalInfo);
    return ( 
        <div className="additional-info">
            <Row>
                <Col md={4} className="mb-3">
                    <Form.Group>
                        <Form.Label>{t('addPerson.isDied')}</Form.Label>
                        <STGSingleSelect onChangeHandler={(e)=> handleAdditionalInfo('is_died',parseInt(e.target.value))} 
                            options={yesOrNoOptions} defaultValue={additionalInfoState.is_died}/>
                    </Form.Group>
                </Col>
                <Col md={4} className="mb-3">
                    <Form.Group>
                        <Form.Label>{t('addPerson.isPriest')}</Form.Label>
                        <STGSingleSelect onChangeHandler={(e)=> handleAdditionalInfo('is_priest',parseInt(e.target.value))} 
                            options={yesOrNoOptions} defaultValue={additionalInfoState.is_priest}/>
                    </Form.Group>
                </Col>
                <Col md={4} className="mb-3">
                    <Form.Group>
                        <Form.Label>{t('addPerson.regularity')}</Form.Label>
                        <STGSingleSelect onChangeHandler={(e)=> handleAdditionalInfo('regularity',e.target.value)} 
                            options={regularityOptions} defaultValue={additionalInfoState.regularity}/>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={3} className="mb-3">
                    <Form.Group>
                        <Form.Label>{t('addPerson.isSpecial')}</Form.Label>
                        <STGSingleSelect onChangeHandler={(e)=> handleAdditionalInfo('is_special',parseInt(e.target.value))} 
                            options={yesOrNoOptions} defaultValue={additionalInfoState.is_special}/>
                    </Form.Group>
                </Col>
                <Col md={3} className="mb-3">
                    <Form.Group>
                        <Form.Label>{t('addPerson.isServed')}</Form.Label>
                        <STGSingleSelect onChangeHandler={(e)=> handleAdditionalInfo('is_served',parseInt(e.target.value))} 
                            options={yesOrNoOptions} defaultValue={additionalInfoState.is_served}/>
                    </Form.Group>
                </Col>
                <Col md={3} className="mb-3">
                    <Form.Group>
                        <Form.Label>{t('addPerson.isPoor')}</Form.Label>
                        <STGSingleSelect onChangeHandler={(e)=> handleAdditionalInfo('is_poor',parseInt(e.target.value))} 
                            options={yesOrNoOptions} defaultValue={additionalInfoState.is_poor}/>
                    </Form.Group>
                </Col>
                <Col md={3} className="mb-3">
                    <Form.Group>
                        <Form.Label>{t('addPerson.isPeople')}</Form.Label>
                        <STGSingleSelect onChangeHandler={(e)=> handleAdditionalInfo('is_people',parseInt(e.target.value))} 
                            options={yesOrNoOptions} defaultValue={additionalInfoState.is_people}/>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6} className="mb-3">
                    <STGGetPostEntity valueField="id" labelField="name" label={t('ChooseStudy')} id="study" 
                        onChangeDropDown={(e)=> handleAdditionalInfo('study_id', parseInt(e.target.value))} 
                        options={studyOptions} disableAdd={disabledAddStudy} defaultValue={additionalInfoState.study_id}
                    >
                        <form id="studyName">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <label className="form-label">{t('Studies.studyName')}</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder={t('Studies.enter')}
                                        value={study.name}
                                        name="name"
                                        maxLength='200'
                                        onChange={e=>setStudy(prev=>{return {...prev,name:e.target.value}})}
                                    />
                                </div>
                                <div className="">
                                    <label className="form-label">{t('Studies.stageName')}</label>
                                    <STGSingleSelect onChangeHandler={(e)=> setStudy(prev=>{return {...prev,parentId:e.target.value}})} options={studyOptions.filter(el=>!el?.parent_id)} 
                                        valueField="id" labelField="name" defaultValue={study.parentId}/>
                                </div>
                            </div>
                            <button className={`btn w-100 m-auto ${study.name?`btn-primary`:`btn-danger`}`} onClick={addٍStudy}  type="button"  disabled={!study.name}>
                                {t('Studies.add')}
                            </button>
                        </form>
                    </STGGetPostEntity>
                </Col>
                <Col md={6} className="mb-3">
                    <Form.Group>
                        <Form.Label>{t('addPerson.maritalStatus')}</Form.Label>
                        <STGSingleSelect onChangeHandler={(e)=> handleAdditionalInfo('marital_status',e.target.value)} 
                            options={maritalStatusOptions} defaultValue={additionalInfoState.marital_status}/>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6} className="mb-3">
                    <STGGetPostEntity valueField="id" labelField="name" label={t('ChooseChurch')} onChangeDropDown={(e)=> handleAdditionalInfo('church_id', parseInt(e.target.value))} 
                        options={churchOptions} disableAdd={disabledAddChurch} defaultValue={additionalInfoState.church_id}
                    >
                        <form>
                            <label className="form-label">{t('Churches.name')}</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder={t('Churches.enter')}
                                value={churchName}
                                name="name"
                                maxLength='200'
                                onChange={e=>setChurchName(e.target.value)}
                            />
                            <button className={`btn w-100 m-auto ${churchName?`btn-primary`:`btn-danger`}`} type="button" onClick={addChurch}  disabled={!churchName}>
                                {t('Churches.add')}
                            </button>
                        </form>
                    </STGGetPostEntity>
                </Col>
                <Col md={6} className="mb-3">
                    <STGGetPostEntity valueField="id" labelField="name" label={t('ChooseJob')} id="job" onChangeDropDown={(e)=> handleAdditionalInfo('job_id', parseInt(e.target.value))} 
                        options={jobOptions} disableAdd={disabledAddjob} defaultValue={additionalInfoState.job_id}
                    >
                        <form>
                            <label className="form-label">{t('Jobs.name')}</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder={t('Jobs.enter')}
                                value={jobName}
                                name="name"
                                maxLength='200'
                                onChange={e=>setJobName(e.target.value)}
                            />
                            <button className={`btn w-100 m-auto ${jobName?`btn-primary`:`btn-danger`}`} type="button" onClick={addJob}  disabled={!jobName}>
                                {t('Jobs.add')}
                            </button>
                        </form>
                    </STGGetPostEntity>
                </Col>
            </Row>
            <Row>
            <Row>
                <Col md={6} className="mb-3">
                    <Form.Group>
                        <Form.Label>{t('addPerson.graduationYear')}</Form.Label>
                        <Datetime
                            timeFormat={false}
                            onChange={(value)=>handleAdditionalInfo('graduation_year',value)}
                            renderInput={(props, openCalendar,closeCalendar) => (
                                <InputGroup>
                                    <InputGroup.Text onClick={openCalendar}><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                                    <Form.Control
                                        type="text"
                                        value={additionalInfoState.graduation_year}
                                        placeholder="YYYY/MM/DD"
                                        onFocus={openCalendar}
                                        onBlur={closeCalendar}
                                    />
                                </InputGroup>
                            )} 
                        />
                    </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                    <STGGetPostEntity valueField="id" labelField="name" label={t('ChooseQ')} id="qualification" onChangeDropDown={(e)=> handleAdditionalInfo('qualification_id', parseInt(e.target.value))} 
                        options={qualificationOptions} disableAdd={disabledAddQualification}  defaultValue={additionalInfoState.qualification_id}
                    >
                        <form id="qualificationName" className="">
                            <label className="form-label">{t('Qualifications.name')}</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder={t('Qualifications.enter')}
                                value={qualificationName}
                                name="name"
                                maxLength='200'
                                onChange={e=>setQualificationName(e.target.value)}
                            />
                            <button className={`btn w-100 m-auto ${qualificationName?`btn-primary`:`btn-danger`}`} type="button" onClick={addQualification}  disabled={!qualificationName}>
                                {t('Qualifications.add')}
                            </button>
                        </form>
                    </STGGetPostEntity>
                </Col>
            </Row>
            </Row>
            <ToastContainer />
        </div> 
    );
}
 
export default AdditionalInfo;