import {get,post } from "./index";

export const me = async () =>{
    const response = await get("/me");
    return response;
}
export const logout = async () =>{
    const response = await post("/logout");
    return response;
}
export const login = async(mail,pass) =>{
    const response = await post("/login",{ "user_name": mail, "password": pass});
    return response;
}

