import React from "react";
import { Card, Row, Col, Modal } from "@themesberg/react-bootstrap";
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import useUpdatePerson from "customHooks/useUpdatePerson";
import GeneralInfo from "./components/GeneralInfo/GeneralInfo";
import AdditionalInfo from "./components/AdditionalInfo/AdditionalInfo";
import FamilyInfo from "./components/FamilyInfo/FamilyInfo";
import ServantInfo from "./components/ServantInfo/ServantInfo";
import { ToastContainer } from "react-toastify";
const UpdatePerson = () => {
    const { t } = useTranslation();
    const {
        person, tab, setTab, 
        error, setError, 
        handleGeneralInfo, generalInfoState,
        additionalInfoState, handleAdditionalInfo,
        familyInfoState, handleFamilyInfo,
        modalOpen, setModalOpen,
        servantInfoState, handleServantInfo,
        sendUpdateRequest
    } = useUpdatePerson(t);

    return (
        <Card className="update-person shadow-sm ">
            {person? 
                <>
                    <div className="tab-wrapper">
                        <div className={`tab ${tab===1 ? 'selected' : ''}` } onClick={()=>setTab(1)}>{t('update.generalInfo')}</div>
                        <div className={`tab ${tab===2 ? 'selected' : ''}` } onClick={()=>setTab(2)}>{t('update.additionalInfo')}</div>
                        <div className={`tab ${tab===3 ? 'selected' : ''}` } onClick={()=>setTab(3)}>{t('update.familyInfo')}</div>
                        <div className={`tab ${tab===4 ? 'selected' : ''}` } onClick={()=>setTab(4)}>{t('update.servantInfo')}</div>
                    </div>
                    <Card.Body>                                
                        {tab === 1 && 
                            <GeneralInfo 
                                setError={setError}
                                generalInfoState={generalInfoState} 
                                handleGeneralInfo={handleGeneralInfo}
                            />
                        }
                        {tab === 2 && 
                            <AdditionalInfo 
                                additionalInfoState={additionalInfoState} 
                                handleAdditionalInfo={handleAdditionalInfo}
                            />
                        }
                        {tab === 3 && 
                            <FamilyInfo 
                                familyInfoState={familyInfoState} handleFamilyInfo={handleFamilyInfo}
                            />
                        }
                        {tab === 4 && 
                            <ServantInfo 
                                servantInfoState={servantInfoState} handleServantInfo={handleServantInfo}
                            />
                        }
                        <Row className="mt-2">
                            <Col md={12}>
                                <button className={`btn w-100 m-auto ${!(error || generalInfoState.full_name === "")?`btn-primary`:`btn-danger`}`}  type="button"  
                                    disabled={(error || generalInfoState.full_name === "")} onClick={() => setModalOpen(true)}
                                >
                                    {t('people.update')}
                                </button>
                            </Col>
                        </Row>
                        <Modal
                            as={Modal.Dialog}
                            centered
                            show={modalOpen}
                            onHide={()=> setModalOpen(false)}
                        >
                            <Modal.Header>
                                <Modal.Title className="h5">{t('update.AreU')}</Modal.Title>
                                <button
                                    type="button" className="btn btn-close modalCloseButton"
                                    aria-label="Close"
                                    onClick={()=> setModalOpen(false)}
                                />
                            </Modal.Header>
                            <Modal.Footer>
                                <button className={`btn w-100 m-auto btn-primary`} disabled={false} onClick={sendUpdateRequest}>{t('presyes')}</button>
                            </Modal.Footer>
                        </Modal>
                    </Card.Body>
                </>
            : <h4 >{t("Churches.Noitems")}</h4>
            }
            <ToastContainer />
        </Card>
    )
};
export default UpdatePerson;