import React,{Suspense} from 'react';
import ReactDOM from 'react-dom'
import { I18nextProvider } from "react-i18next";
import i18n from "locales/i18n";
import Preloader from "components/Preloader";
import "./scss/volt.scss";
import "react-datetime/css/react-datetime.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import HomePage from "./pages/HomePage";
import AuthProvider  from 'contexts/AuthProvider';
import { createRoot } from 'react-dom/client';

const renderPreloader = ()=>{
  return <Preloader show={true} />
}
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
<React.StrictMode>
  <div className='app-wrapper'>
    <AuthProvider>
      <Suspense fallback={renderPreloader}>
        <I18nextProvider i18n={i18n}>
            <HomePage />
        </I18nextProvider>
      </Suspense>
    </AuthProvider>
  </div>
  </React.StrictMode>
);
// ReactDOM.render(
//   <div className='app-wrapper'>
//     <AuthProvider>
//       <Suspense fallback={renderPreloader}>
//         <I18nextProvider i18n={i18n}>
//             <HomePage />
//         </I18nextProvider>
//       </Suspense>
//     </AuthProvider>
//   </div>,
//   document.getElementById("root")
// );
