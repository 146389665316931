import React from "react";
import {
  Modal
} from "@themesberg/react-bootstrap";
import useAreaCRUD from "customHooks/useAreaCRUD";
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
import STGButton from "components/STGButton";

const Areas = () => {
  const {
    areas,
    onOpenUpdateModal,
    deleteHandler,
    onOpenCreateModal,
    showCreateAreaModal,
    handleCreateModalClose,
    setNewArea,
    newArea,
    showUpdateAreaModal,
    handleUpdateModalClose,
    updatedAreaName,
    OnChangeUpdateAreaHandler,
    sendUpdateRequest,
    addArea,
    clickLoading
  } = useAreaCRUD();
  const { t } = useTranslation();
  
  return (
    <>
      <div className="container px-0 py-2">
        <section>
          <div className="d-flex align-items-start">
            <h2>{t('Areas.listOfareas')}</h2>
            <button className="btn btn-primary btn-sm jobs__add-button" onClick={onOpenCreateModal}>
              {t('Areas.add')}
            </button>
          </div>
          <hr />
          <div className="row" style={{overflowY: "hidden"}}>
           {areas.length>0 ?
           <div className="table-responsive">
              <table className="w-75 table table-striped table-bordered table-hover">
                <thead className="px-0 bg-info">
                  <tr>
                    <th className="">
                      <h5>{t('Areas.name')} </h5>
                    </th>
                    <th className="">
                      <h5>{t('Areas.Actions')} </h5>
                    </th>
                  </tr>
                  <tr>
                  </tr>
                </thead>
                <tbody>
                  {areas.map((area, index) => (
                      <tr key={index}>
                        <td className="">
                            <span className="h6">{area.name}</span>
                          <div>
                          </div>
                        </td>
                        <td className="">
                            <button  className="btn btn-primary"  onClick={()=>onOpenUpdateModal(area)} >
                            {t('Areas.update')}
                            </button>
                            <button className="mx-2 btn btn-danger"  onClick={()=>deleteHandler(area.id)} >
                            {t('Areas.delete')}
                            </button>
                        </td>
                      </tr>
                  ))}
                </tbody>
              </table>
           </div>
            :
            <h2 className="text-danger">{t('Areas.Noitems')}</h2>
           } 
          </div>
        </section>

        {/* Add new area modal  */}
        <section>
          <Modal
            as={Modal.Dialog}
            centered
            show={showCreateAreaModal}
            onHide={handleCreateModalClose}
          >
            <Modal.Header>
              <Modal.Title className="h6">{t('Areas.add')}</Modal.Title>
              <button
                type="button" className="btn btn-close modalCloseButton"
                aria-label="Close"
                onClick={handleCreateModalClose}
              />
            </Modal.Header>
            <Modal.Body>
             
              <div className="col-lg-8 col-md-12">
                <form id="areaName">
                  <label className="form-label">{t('Areas.name')}</label>
                  <input
                    required
                    className="form-control"
                    type="text"
                    maxLength={'40'}
                    placeholder={t('Areas.enter')}
                    value={newArea}
                    onChange={(e)=>setNewArea(e.target.value)}
                  />
                </form>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <STGButton classes="w-100 m-auto" loading={clickLoading} t={t} onCLickHandler={addArea} disabled={!newArea}>
                {t('Areas.add')}
              </STGButton>
            </Modal.Footer>
          </Modal>
        </section>

        {/* Update new Area modal  */}
        <section>
          <Modal
            as={Modal.Dialog}
            centered
            show={showUpdateAreaModal}
            onHide={handleUpdateModalClose}
          >
            <Modal.Header>
              <Modal.Title className="h6">{t('Areas.updateCurrent')}</Modal.Title>
              <button
                aria-label="Close"
                type="button" className="btn btn-close modalCloseButton"
                onClick={handleUpdateModalClose}
              />
            </Modal.Header>
            <Modal.Body>
             
              <div className="col-lg-8 col-md-12">
                <form id="areaNameUpdated">
                  <label className="form-label">{t('Areas.areaName')}</label>
                  <input
                    required
                    className="form-control"
                    type="text"
                    placeholder={t('Areas.enter')}
                    value={updatedAreaName}
                    onChange={OnChangeUpdateAreaHandler}
                  />
                </form>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <STGButton classes="w-100 m-auto" loading={clickLoading} t={t} onCLickHandler={sendUpdateRequest} disabled={!updatedAreaName}>
                {t('Areas.updateCurrent')}
              </STGButton>
            </Modal.Footer>
          </Modal>
        </section>
        <ToastContainer />
      </div>
    </>
  );
};
export default Areas;