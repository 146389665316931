import { useState, useEffect,useReducer } from "react";
import { useTranslation } from 'react-i18next';
import moment from "moment-timezone";
import { arabicName,englishName,nationalIdRegex,emailRegex,phoneRegex } from "utils/regex";
import { getAllChurches, createNewChurch} from "services/churchService";
import { getAllStreets, createNewStreet} from "services/streetService";
import { getAllJobs, createNewJob} from "services/jobService";
import { getAllAreas } from "services/areaService";
import { getAllQualifications, createNewQualification} from "services/qualificationService";
import {getAllStudies , createNewStudy} from "services/studyService";
import {createNewPerson, getAllPeople} from "services/peopleService";
import { toast } from 'react-toastify';
import {removeUnusedObjectProps, retrieveStudyName, removeEmptyFields} from "utils/peopleutil";

const useAddPersonCRUD = () => {
  const { t } = useTranslation();
  const [streets,setStreets] = useState([]);
  const [street,setStreet] = useState({name: "",area_id:null});
  const [disabledAddStreet , setDisabledAddStreet] = useState(false);
  const [churches,setChurches] = useState([]);
  const [churchName,setChurchName] = useState("");
  const [disabledAddChurch , setDisabledAddChurch] = useState(false);
  const [jobs,setJobs] = useState([]);
  const [jobName,setJobName] = useState("");
  const [disabledAddjob , setDisabledAddjob] = useState(false);
  const [qualifications,setQualifications] = useState([]);
  const [qualificationName,setQualificationName] = useState("");
  const [disabledAddQualification , setDisabledAddQualification] = useState(false);
  const [studies,setStudies] = useState([]);
  const [study,setStudy] = useState({name: "",parentId:null});
  const [disabledAddStudy , setDisabledAddStudy] = useState(false);
  const [areas,setAreas] = useState([]);
  const [people,setPeople] = useState([]);
  const [relationIds, setRelationIds] = useState({
    father_id: NaN,
    mother_id: NaN,
    partner_id: NaN
  });
  const [brothers_id, setBrothersId] = useState([]);
  const genderOptions = [
    {name:t('addPerson.gender'),value:""},
    {name:t('addPerson.male'),value:"ذكر"},
    {name:t('addPerson.female'),value:"أنثي"}
  ]
  const maritalStatusOptions = [
    {name:t('addPerson.maritalStatus'),value:""},
    {name:t('addPerson.single'),value:"أعزب"},
    {name:t('addPerson.married'),value:"متزوج"},
    {name:t('addPerson.broken'),value:"منفصل"},
    {name:t('addPerson.divorced'),value:"مطلق"}
  ]
  const regularityOptions = [
    {name:t('addPerson.regularity'),value:""},
    {name:t('addPerson.regular'),value:"منتظم"},
    {name:t('addPerson.rare'),value:"غير منتظم"}
  ]
  const yesOrNoOptions = [
    {name:t('dontKnow'),value:NaN},
    {name:t('yes'),value:1},
    {name:t('no'),value: 0}
  ]
  const initialPersonState = {
    full_name: "",
    birth_date : "",
    gender : genderOptions[0].value,
    marital_status : maritalStatusOptions[0].value,
    marriage_date : "",
    national_id : "",
    graduation_year : "",
    regularity : regularityOptions[0].value,
    is_died : NaN,
    is_servant : NaN,
    is_priest : NaN,
    is_poor : NaN,
    is_special : NaN,
    is_served : NaN,
    is_people : NaN,
    email : "",
    phones : [{
      "phone_prefix": "",
      "phone": ""
    }],
    church_id : NaN,
    job_id : NaN,
    qualification_id : NaN,
    study_id : NaN
  };
  const initialStreetState = {
    street_id : NaN,
    building_number : "",
    floor_number : "",
    apartment_number: "",
    description:"",
    map:"",
  }
  const initialServiceState = {
    additional_studies: "",
    special_gifts: "",
    servant_graduation_year: "",
    graduation_class_reps: "",
    additional_services_out: "" 
  }
  const initialErrorFields = {
    fullName: {hasError : false , errorMessage : [t('validation.name1'),t('validation.name2')] },
    nationalId: {hasError : false , errorMessage : [t('validation')] },
    email : {hasError : false , errorMessage : [t('validation')] },
    phone : {hasError : false , errorMessage : [''] },
  };
  useEffect(() => {
   fetchChurches();
   fetchJobs();
   fetchQualifications();
   fetchStudies();
   fetchStreets();
   fetchAreas();
   fetchPeople();
  }, [])
  const reducer = (state,action) =>{
    switch (action.type) {
      case "SET_INPUT":
        return {
          ...state,
          [action.field]:action.payload
        }
      case "RESET":
        return {
          ...initialPersonState
        }
      default: 
        return state;
    }
  }
  const reducerService = (state,action) =>{
    switch (action.type) {
      case "SET_INPUT":
        return {
          ...state,
          [action.field]:action.payload
        }
      case "RESET":
        return {
          ...initialStreetState
        }
      default: 
        return state;
    }
  }
  const reducerError = (state,action) => {
    const tempField = {...state};
    tempField[action.field].hasError = action.payload;
    switch (action.type) {
      case "SET_INPUT":
        return {
          ...tempField
        }
      case "RESET":
        return {
          ...initialErrorFields
        }
      default: 
      return state;
    }
  }
  const reducerStreet = (state,action) =>{
    switch (action.type) {
      case "SET_INPUT":
        return {
          ...state,
          [action.field]:action.payload
        }
      case "RESET":
        return {
          ...initialStreetState
        }
      default: 
        return state;
    }
  }
  const [personState, dispatch] = useReducer(reducer, initialPersonState);
  const [streetState, dispatchStreet] = useReducer(reducerStreet, initialStreetState);
  const [serviceState, dispatchService] = useReducer(reducerService, initialServiceState);
  const [errorFieldsState, dispatchError] = useReducer(reducerError, initialErrorFields);
  const handleYesOrNoFields = (e,field) => {
    dispatch({
      type: "SET_INPUT",
      field: field,
      payload: parseInt(e.target.value)
    });
  }
  const handleIdFields = (value,field) =>{
    dispatch({
      type: "SET_INPUT",
      field: field,
      payload: parseInt(value)
    });
  }
  const handleRelationIds = (value,field) => {
    if(field !== "brothers_id"){
      setRelationIds({...relationIds, [field]:parseInt(value)})
    }
    else{
      setBrothersId(value.map(option => option.id))
    }
  }
  const handleStreetStateChange = (value,field) => {
    if(field === 'street_id'){
      dispatchStreet({
        type: "SET_INPUT",
        field: field,
        payload:parseInt(value)
      });
    }
    else{
      dispatchStreet({
        type: "SET_INPUT",
        field: field,
        payload:value
      });
    }
  }
  const handleServiceStateChange = (e,field) => {
    if(field === 'servant_graduation_year'){
      dispatchService({
        type: "SET_INPUT",
        field: field,
        payload: moment(e).format("YYYY/MM/DD") || ""
      });
    }
    else{
      dispatchService({
        type: "SET_INPUT",
        field: field,
        payload:e.target.value
      });
    }
  }
  const handlePersonStateChange = (e,field) => {
    switch (field) {
        case 'full_name':
            dispatch({
                type: "SET_INPUT",
                field: field,
                payload:e.target.value
            });
            validateFullName(e.target.value);
            break;
        case 'birth_date':
            dispatch({
                type: "SET_INPUT",
                field: field,
                payload: moment(e).format("YYYY/MM/DD") || ""
            });
            break;
        case 'marriage_date':
          dispatch({
              type: "SET_INPUT",
              field: field,
              payload: moment(e).format("YYYY/MM/DD") || ""
          });
          case 'graduation_year':
            dispatch({
                type: "SET_INPUT",
                field: field,
                payload: moment(e).format("YYYY/MM/DD") || ""
            });
          
          case 'national_id':            
            dispatch({
                type: "SET_INPUT",
                field: field,
                payload:e.target.value
              });
              validateNationalId(e.target.value);
          break;
          case 'email':
            dispatch({
                type: "SET_INPUT",
                field: field,
                payload:e.target.value
            });
            validateEmail(e.target.value);
          break;
          case 'phones':
            dispatch({
                type: "SET_INPUT",
                field: field,
                payload:e
            });
          break;
        default:
          dispatch({
            type: "SET_INPUT",
            field: field,
            payload: e.target.value
          });
    }
  }
  const validateFullName =(fullName)=>{
    dispatchError({
        type: "SET_INPUT",
        field:'fullName',
        payload:(fullName.length < 5 || (!arabicName.test(fullName) && !englishName.test(fullName)))
      });
  }
  const validateNationalId =(nationalId)=>{
    dispatchError({
        type: "SET_INPUT",
        field:'nationalId',
        payload:(nationalId && (nationalId.length !==14 || !nationalIdRegex.test(nationalId)) )
      });
  }
  const validateEmail =(email)=>{
    dispatchError({
        type: "SET_INPUT",
        field:'email',
        payload: email!=="" ?(email.length < 5 || !emailRegex.test(email)):false
      });
  }
  const handlePhoneError = (hasError) =>{
    dispatchError({
      type: "SET_INPUT",
      field:'phone',
      payload: hasError
    });
  }

  const fetchChurches = async () => {
    const response = await getAllChurches();
    if (response.status === 200) {
      setChurches(response?.data?.data);
    }
  };
  const fetchPeople = async () => {
    const response = await getAllPeople();
    if (response.status === 200) {
      setPeople(response?.data?.data);
    }
  };
  const fetchJobs = async () => {
    const response = await getAllJobs();
    if (response.status === 200) {
      setJobs(response?.data?.data);
    }
  };
  const fetchQualifications = async () => {
    const response = await getAllQualifications();
    if (response.status === 200) {
      setQualifications(response?.data?.data);
    }
  };
  const fetchStreets = async () => {
    const response = await getAllStreets();
    if (response.status === 200) {
      setStreets(response?.data?.data);
    }
  };
  const fetchAreas = async () => {
    const response = await getAllAreas();
    if (response.status === 200) {
      setAreas(response?.data?.data);
    }
  };
  const fetchStudies = async () => {
    const response = await getAllStudies();
    if (response.status === 200) {
      const sortedItems = response?.data?.data;
      for (let index = 0; index < sortedItems.length; index++) {
        sortedItems[index].name = retrieveStudyName(sortedItems[index]) ;
      }
      setStudies(sortedItems);
    }
  };
 
  const addChurch = async () => {
    const response = await createNewChurch({name:churchName});
    if(response.data.success){
        toast.success(t('ProcesSucceeded'), {
            position: 'top-left',
            autoClose: true,
            icon: "✅"
        });
        handleIdFields(response.data.data.id,'church_id')
        fetchChurches();
        setChurchName("");
        setDisabledAddChurch(true);
    }
    else{
        toast.error(response.data.message || t('ProcessFailed'), {
            position: 'top-left',
            autoClose: true,
            progress:0.2
        });
    }
  };
  const addJob = async () => {
    const response = await createNewJob(jobName);
    if(response.data.success){
        toast.success(t('ProcesSucceeded'), {
            position: 'top-left',
            autoClose: true,
            icon: "✅"
        });
        handleIdFields(response.data.data.id,'job_id')
        fetchJobs();
        setJobName("");
        setDisabledAddjob(true);
    }
    else{
        toast.error(response.data.message || t('ProcessFailed'), {
            position: 'top-left',
            autoClose: true,
            progress:0.2
        });
    }
  };
  const addQualification = async () => {
    const response = await createNewQualification(qualificationName);
    if(response.data.success){
        toast.success(t('ProcesSucceeded'), {
            position: 'top-left',
            autoClose: true,
            icon: "✅"
        });
        handleIdFields(response.data.data.id,'qualification_id')
        fetchQualifications();
        setQualificationName("");
        setDisabledAddQualification(true);
    }
    else{
        toast.error(response.data.message || t('ProcessFailed'), {
            position : 'top-left',
            autoClose : true,
            progress : 0.2
        });
    }
  };
  const addStreet = async () => {
    const response = await createNewStreet(street.name,street.area_id);
    if(response.data.success){
        toast.success(t('ProcesSucceeded'), {
            position: 'top-left',
            autoClose: true,
            icon: "✅"
        });
        handleStreetStateChange(response.data.data.id,'street_id')
        fetchStreets();
        setStreet({name: "",area_id:null});
        setDisabledAddStreet(true);
    }
    else{
        toast.error(response.data.message || t('ProcessFailed'), {
            position: 'top-left',
            autoClose: true,
            progress:0.2
        });
    }
  };
  const addٍStudy = async () => {
    const response = await createNewStudy(study.name,study.parentId);
    if(response.data.success){
        toast.success(t('ProcesSucceeded'), {
            position: 'top-left',
            autoClose: true,
            icon: "✅"
        });
        handleIdFields(response.data.data.id,'study_id')
        fetchStudies();
        setStudy({name: "",parentId:null});
        setDisabledAddStudy(true);
    }
    else{
        toast.error(response.data.message || t('ProcessFailed'), {
            position: 'top-left',
            autoClose: true,
            progress:0.2
        });
    }
  };
  const addPerson = async() =>{
    let formattedPersonState = {...personState, ...relationIds};
    formattedPersonState.brothers_id = [...brothers_id];
    formattedPersonState.phones  = removeEmptyFields(formattedPersonState.phones, 'phone', '');
    if(formattedPersonState.email){
      formattedPersonState.emails = [formattedPersonState.email];
    }
    let tempFormattedState = removeUnusedObjectProps(formattedPersonState);
    tempFormattedState = {...tempFormattedState, ...serviceState};
    delete tempFormattedState.email;
    if(streetState.street_id){
      tempFormattedState = {...tempFormattedState, addresses : [{...streetState}]};
      tempFormattedState.addresses[0].map = [tempFormattedState.addresses[0].map]
    }
    const response = await createNewPerson(tempFormattedState);
    if(response.data.success){
        toast.success(t('ProcesSucceeded'), {
            position: 'top-left',
            autoClose: true,
            icon: "✅"
        });  
        dispatch({type: "RESET"});
        dispatchStreet({type:"RESET"});
        dispatchService({type:"RESET"});
        setRelationIds({
          father_id: NaN,
          mother_id: NaN,
          partner_id: NaN
        });
        setBrothersId([]);
        fetchPeople(); 
    }
    else{
        toast.error(response.data.message || t('ProcessFailed'), {
            position: 'top-left',
            autoClose: true,
            progress:0.2
        });
    }
  }

  return {
    personState,
    handlePersonStateChange,
    errorFieldsState,
    maritalStatusOptions,
    genderOptions,
    regularityOptions,
    handleYesOrNoFields,
    yesOrNoOptions,
    handleIdFields,

    churches,
    churchName,
    setChurchName,
    addChurch,
    disabledAddChurch,

    jobs,
    jobName,
    setJobName,
    addJob,
    disabledAddjob,

    qualifications,
    qualificationName,
    setQualificationName,
    disabledAddQualification,
    addQualification, 

    studies,
    study,
    setStudy,
    addٍStudy,
    disabledAddStudy,

    addPerson,
    handleStreetStateChange,
    streetState,

    streets,
    street,
    setStreet,
    addStreet,
    disabledAddStreet,

    areas, 

    handleServiceStateChange,
    serviceState,

    people,
    setRelationIds, 
    relationIds,
    handleRelationIds,
    brothers_id,
    handlePhoneError

  }
}
export default useAddPersonCRUD;
