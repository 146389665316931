
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Card, Button, FormCheck, Container, InputGroup } from '@themesberg/react-bootstrap';
import { useTranslation } from 'react-i18next';
import {login} from "services/authService";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { ToastContainer } from "react-toastify";
import useAuth from "contexts/useAuth";

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState({email:"",pass:""})
  // const [error, setError] = useState({hasError : false , errorMessage : t('validation') });
  const { setAuth } = useAuth();
  
  const submitHandler = async () =>{
    if(loginData.pass !== "" || loginData.email !== ""){
      try {
        const response = await login(loginData.email,loginData.pass);
        if (response.status === 200) {
          localStorage.setItem("isUserLogged","true");
          setAuth({token: response.data.data[0].access_token, isLogged: true});
          localStorage.setItem("access_token",response.data.data[0].access_token);
          localStorage.setItem("my_data",JSON.stringify(response.data.data[0]));
          navigate("/person/get",{replace:true});
        }
        else{
          toast.error(t('ProcessFailed'), {
            position: 'top-left',
            autoClose: true,
            progress:0.2
          });
        }
      } catch (error) {
        console.log(error)
      }  
    } 
  }
  const changeHandler = (field,value) =>{
    setLoginData({...loginData, [field]:value});
    // if(field === "email"){
    //     if(loginData.email.length < 5 || !emailRegex.test(loginData.email) ){
    //         setError({hasError:true, errorMessage : t('validation')})
    //     }
    //     else 
    //         setError({hasError:false, errorMessage : t('validation')})
    // }
  }

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5 login-form">
        <Container>
          <Row className="justify-content-center form-bg-image" >
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">{t('Login')}</h3>
                </div>
                {/* {error.hasError && 
                    <div className="login-error-message">
                        <span>{error.errorMessage}</span>
                    </div>
                } */}
                <Form className="mt-4">
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>{t('Login.User')}</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control autoFocus required type="email" placeholder="example@company.com" onChange={(e)=> changeHandler("email",e.target.value)} />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>{t('Login.Pass')}</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control required type="password" placeholder="Password" onChange={(e)=> changeHandler("pass",e.target.value)}/>
                      </InputGroup>
                    </Form.Group>
                  </Form.Group>
                  <Button variant="primary" type="button" className={`w-100 ${(loginData.pass !== "" && loginData.email !== "" )?`btn-primary`:`btn-danger`}`} disabled={loginData.pass === "" || loginData.email === ""} onClick={submitHandler}>
                    {t('Login')}
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
          <ToastContainer />
        </Container>
      </section>
    </main>
  );
};
export default Login;
