import React from "react";
import {
  Modal, Col
} from "@themesberg/react-bootstrap";
import useStudyCRUD from "customHooks/useStudyCRUD";
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
import {Dropdown,DropdownButton} from 'react-bootstrap';
import STGButton from "components/STGButton";

const Studies = () =>{
    const {
        onOpenCreateModal,studies,deleteHandler,showCreateModal,handleNewStudyNameChange,showUpdateModal,
        handleCreateModalClose,Studystate,addStudy, selectedTab,setSelectedTab,stages,onChangeStage,handleUpdateModalClose
        ,onOpenUpdateModal,updatedStudystate,handleUpdatedStudyNameChange,sendUpdateRequest, clickLoading
    } = useStudyCRUD();
    const { t } = useTranslation();

    return (
        <>
        <div className="container px-0 py-2">
            <section>
                <div className="d-flex align-items-start">
                    <h2>{t('Studies.listOfstudies')}</h2>
                    <button className="btn btn-primary btn-sm jobs__add-button" onClick={onOpenCreateModal}>
                        {t('Studies.add')}
                    </button>
                </div>
                <hr />
                <div className="row" style={{overflowY: "hidden"}}>
                {studies.length>0 ?
                    <div className="table-responsive">
                        <table className="w-75 table table-striped table-bordered table-hover">
                            <thead className="px-0 bg-info">
                            <tr>
                                <th className="">
                                <h5>{t('Studies.name')} </h5>
                                </th>
                                <th className="">
                                <h5>{t('Studies.stageName')} </h5>
                                </th>
                                <th className="">
                                <h5>{t('Streets.Actions')} </h5>
                                </th>
                            </tr>
                            <tr>
                            </tr>
                            </thead>
                            <tbody>
                            {studies.map((study, index) => (
                                <tr key={index}>
                                    <td className="">
                                        <span className="h6">{study.name}</span>
                                    </td>
                                    <td className="">
                                        <span className="h6">{study?.parent?.name || '-----'}</span>
                                    </td>
                                    <td className="">
                                        <button  className="btn btn-primary"  onClick={()=>onOpenUpdateModal(study)} >
                                            {t('Studies.update')}
                                        </button>
                                        <button className="mx-2 btn btn-danger"  onClick={()=>deleteHandler(study.id)}>
                                            {t('Studies.delete')}
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                    :
                    <h2 className="text-danger">{t('Studies.Noitems')}</h2>
                } 
                </div>
            </section>
            {/* Add new study modal  */}
            <section>   
                <Modal
                    as={Modal.Dialog}
                    centered
                    show={showCreateModal}
                    onHide={handleCreateModalClose}
                >
                    <Modal.Header>
                    <Modal.Title className="h5">{t('Studies.add')}</Modal.Title>
                    <button
                        type="button" className="btn btn-close modalCloseButton"
                        aria-label="Close"
                        onClick={handleCreateModalClose}
                    />
                    </Modal.Header>
                    <Modal.Body>
                    
                    <div className="">
                        <form id="studyName" className="">
                            <div className="studies__create-modal__form__header1">{t('Studies.chooseInput')}</div>
                            <br />
                            <div className="d-flex justify-content-around">
                                <div className={`studies__create-modal__form__div1 ${selectedTab===1?'selected-radio':''}`}>
                                    <input className={`form-check-input`} type="radio" id="year" name="studiesMode" onChange={()=>setSelectedTab(1)} checked={selectedTab===1}/>
                                    <label className="text-black mx-1" htmlFor="year">{t('Studies.year')}</label>
                                </div>
                                <div className={`studies__create-modal__form__div2 ${selectedTab===2?'selected-radio':''}`}>
                                    <input className={`form-check-input ${selectedTab===2?'selected-radio':''}`} type="radio" id="stage" name="studiesMode" onChange={()=>setSelectedTab(2)} checked={selectedTab===2}/>
                                    <label className="text-black mx-1" htmlFor="stage">{t('Studies.stage')}</label>
                                </div>
                            </div>
                            {selectedTab===2 ?
                                <div className="mt-2">
                                    <label className="form-label">{t('Studies.stageName')}</label>
                                    <input
                                        required
                                        className="form-control"
                                        type="text"
                                        placeholder={t('Studies.enterStageName')}
                                        value={Studystate.name}
                                        name="name"
                                        onChange={handleNewStudyNameChange}
                                    />
                                </div>             
                            : 
                            <div className="mt-4 d-flex studies-stage-wrapper">
                                <Col md={6} className="">
                                    <div className="form-group">
                                        <label className="form-label">{t('Studies.studyName')}</label>
                                        <input
                                            required
                                            className="form-control"
                                            type="text"
                                            placeholder={t('Studies.enter')}
                                            value={Studystate.name}
                                            name="name"
                                            onChange={handleNewStudyNameChange}
                                        />
                                    </div>
                                </Col>
                                <Col md={6} className="studies-stage-drop-down">
                                    <div className="form-group">
                                        <label className="form-label">{t('Studies.stageName')}</label>
                                        <DropdownButton id="dropdown-basic-button" title={Studystate?.stageName?Studystate?.stageName:t('Studies.chooseStage')} value={Studystate?.stageName}>
                                            {(stages && stages.length>0) && stages?.map((stage,idx)=>{
                                                return(
                                                <Dropdown.Item onClick={()=>onChangeStage(stage)} value={stage.name} key={idx}>{stage.name}</Dropdown.Item>

                                                )
                                            })}
                                            {(!stages || stages.length===0 || !Array.isArray(stages)) && <Dropdown.Item value={''}>{t('Studies.Noitems')}</Dropdown.Item>}
                                        </DropdownButton>
                                    </div>
                                </Col>
                            </div>
                            }
                        </form>
                    </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <STGButton classes="w-100 m-auto" loading={clickLoading} t={t} onCLickHandler={addStudy} disabled={!Studystate.name}>
                            {t('Studies.add')}
                        </STGButton>
                    </Modal.Footer>
                </Modal>
            </section>
            {/* Update new study modal  */}
            <section>   
                <Modal
                    as={Modal.Dialog}
                    centered
                    show={showUpdateModal}
                    onHide={handleUpdateModalClose}
                >
                    <Modal.Header>
                        <Modal.Title className="h5">{t('Studies.updateCurrent')}</Modal.Title>
                        <button
                            type="button" className="btn btn-close modalCloseButton"
                            aria-label="Close"
                            onClick={handleUpdateModalClose}
                        />
                    </Modal.Header>
                    <Modal.Body>
                    
                    <div className="">
                        <form id="studyUpdatedName" className="">
                            <div className="mt-4 d-flex justify-content-between">
                                <div className="">
                                    <label className="form-label">{t('Studies.studyName')}</label>
                                    <input
                                    required
                                    className="form-control"
                                    type="text"
                                    placeholder={t('Studies.enter')}
                                    value={updatedStudystate.name}
                                    name="name"
                                    onChange={handleUpdatedStudyNameChange}
                                    />
                                </div>
                                <div className="">
                                    <label className="form-label">{t('Studies.stageName')}</label>
                                    <DropdownButton id="dropdown-basic-button" title={updatedStudystate?.stageName?updatedStudystate?.stageName:t('Studies.chooseStage')} value={updatedStudystate?.stageName}>
                                        {(stages && stages.length>0) && stages?.map((stage,idx)=>{
                                            return(
                                            <Dropdown.Item onClick={()=>onChangeStage(stage,true)} value={stage.name} key={idx}>{stage.name}</Dropdown.Item>

                                            )
                                        })}
                                        {(!stages || stages.length===0 || !Array.isArray(stages)) && <Dropdown.Item value={''}>{t('Studies.Noitems')}</Dropdown.Item>}
                                    </DropdownButton>
                                </div>

                            </div>
                        </form>
                    </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <STGButton classes="w-100 m-auto" loading={clickLoading} t={t} onCLickHandler={sendUpdateRequest} disabled={!updatedStudystate.name}>
                            {t('Studies.update')}
                        </STGButton>
                    </Modal.Footer>
                </Modal>
            </section>
            <ToastContainer />
        </div>
        </>
    );
}
export default Studies;