export const removeUnusedObjectProps = (object) =>{
    const formattedObj = {...object};
    for (const key in formattedObj) {
        if((Array.isArray(formattedObj[key]) && formattedObj[key].length <=0)){
          delete formattedObj[key];
        }
        else if(!Array.isArray(formattedObj[key]) && ((typeof formattedObj[key] !== "string" && isNaN(formattedObj[key])) || formattedObj[key] === "") ){
          delete formattedObj[key];
        }
    }
    return formattedObj || {};
}
export const retrieveStudyName = (studyName) =>{
  if(!studyName) return "";
  return (studyName?.parent?.name) ? `${studyName.name}-${studyName?.parent?.name}` : studyName.name
}
export const removeEmptyFields = (arr, key, value) =>{
  if(Array.isArray(arr) && arr.length > 0){
    return arr.filter(item => item[key]!== value);
  }
  return [];
}