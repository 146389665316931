
const MyRoutes = {
    // pages
    DefaultPath: { path: "/person/get" },
    Jobs: { path: "/jobs" },
    Qualifications: { path: "/qualifications" },
    Areas: { path: "/areas" },
    Streets: { path: "/streets" },
    Churches: { path: "/churches" },
    Studies: { path: "/studies" },
    addPerson : { path : "/person/add"},
    getPeople : { path : "/person/get"},
    personDetail : { path : "/person/detail"},
    personUpdate : { path : "/person/update"}
};
export default MyRoutes; 