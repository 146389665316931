const useServantInfo = (t) => {
      //methods 
      //constants 
      const yesOrNoOptions = [
        {name:t('dontKnow'),value:NaN},
        {name:t('yes'),value:1},
        {name:t('no'),value: 0}
      ]
      //reducers
      return{ yesOrNoOptions }
}

export default useServantInfo;