import { Col, Row, Form, InputGroup} from "@themesberg/react-bootstrap";
import { useTranslation } from 'react-i18next';
import Datetime from "react-datetime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import STGSingleSelect from "components/STGSingleSelect";
import useGeneralInfo from "./hooks/useGeneralInfo";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
import STGGetPostEntity from "components/STGGetPostEntity";
import STGPhone from 'components/STGPhone/STGPhone';

const GeneralInfo = ({setError, generalInfoState, handleGeneralInfo}) => {
    const { t } = useTranslation();
    const {
        genderOptions,
        streetOptions, street, 
        setStreet, addStreet, 
        disabledAddStreet, areasOptions,
        errorFieldsState, handlePhoneError
    } = useGeneralInfo(t, generalInfoState, setError, handleGeneralInfo);
    return (
        <div className="general-info">
            <Row>
                <Col md={6} className="mb-3">
                    <Form.Group className={ errorFieldsState?.fullName?.hasError ? 'error-field' : ''}>
                        <Form.Label>{t('addPerson.fullName')} *</Form.Label>
                        <Form.Control required type="text" placeholder={t('addPerson.enterFullName')} name={'full_name'} value={generalInfoState.full_name} onChange={(e)=>handleGeneralInfo('full_name',e.target.value)}/>
                        <div className="error-message">
                            <ul>
                                {errorFieldsState?.fullName?.errorMessage.map((error,idx)=>
                                    <li key={idx}>{error}</li>
                                )}
                            </ul>
                        </div>
                    </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                    <Form.Group id="birthday">
                        <Form.Label>{t('addPerson.birthday')}</Form.Label>
                        <Datetime
                            timeFormat={false}
                            onChange={(value)=>handleGeneralInfo('birth_date',value)}
                            renderInput={(props, openCalendar,closeCalendar) => (
                                <InputGroup>
                                    <InputGroup.Text onClick={openCalendar}><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                                    <Form.Control
                                        type="text"
                                        value={generalInfoState.birth_date}
                                        placeholder="YYYY/MM/DD"
                                        onFocus={openCalendar}
                                        onBlur={closeCalendar}
                                    />
                                </InputGroup>
                            )} 
                        />
                    </Form.Group>
                </Col>
            </Row>  
            <Row className="mb-3">
                <Col md={6} className="">
                    <Form.Group>
                        <Form.Label>{t('addPerson.gender')}</Form.Label>
                        <STGSingleSelect onChangeHandler={(e)=> handleGeneralInfo('gender', e.target.value)} 
                            options={genderOptions} defaultValue={generalInfoState.gender} 
                        />
                    </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                    <Form.Group className={errorFieldsState?.nationalId?.hasError ? 'error-field' : ''}>
                        <Form.Label>{t('addPerson.nationalId')}</Form.Label>
                        <Form.Control type="text" placeholder={t('addPerson.nationalId')} 
                            value={generalInfoState.national_id} onChange={(e)=>handleGeneralInfo('national_id',e.target.value)}
                        />
                        <div className="error-message">
                            <ul>
                                {errorFieldsState?.nationalId?.errorMessage.map((error,idx)=>
                                    <li key={idx}>{error}</li>
                                )}
                            </ul>
                        </div>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md={6} className="">
                    <STGPhone 
                        initialValue={generalInfoState.phones} 
                        onChangeHandler={(phones)=>handleGeneralInfo('phones', phones)}
                        handlePhoneError={handlePhoneError}
                    />
                </Col>
                <Col md={6} className="">
                    <Form.Group id="email" className={errorFieldsState?.email?.hasError ? 'error-field' : ''}>
                        <Form.Label>{t('addPerson.addEmail')}</Form.Label>
                        <Form.Control type="email" placeholder={t('addPerson.addEmail')} name={'email'} value={generalInfoState.email} onChange={(e)=>handleGeneralInfo('email', e.target.value)}/>
                        <div className="error-message">
                            <ul>
                                {errorFieldsState?.email?.errorMessage.map((error,idx)=>
                                    <li key={idx}>{error}</li>
                                )}
                            </ul>
                        </div>
                    </Form.Group>
                </Col>
            </Row>

            {/* Address details  */}
            <Row>
                <Col md={4} className="mb-3">
                    <STGGetPostEntity valueField="id" labelField="name" label={t('addPerson.streetChoose')}
                        onChangeDropDown={(e)=> handleGeneralInfo('street_id', parseInt(e.target.value))} 
                        options={streetOptions} disableAdd={disabledAddStreet} defaultValue={generalInfoState.street_id}
                    >
                        <form className="d-flex justify-content-between">
                            <div>
                                <label className="form-label">{t('Streets.name')}</label>
                                <input
                                    required
                                    className="form-control"
                                    type="text"
                                    placeholder={t('Streets.enter')}
                                    value={street.name}
                                    name="street"
                                    onChange={e=>setStreet(prev=>{return {...prev,name:e.target.value}})}
                                />
                            </div>
                            <div className="">
                                <label className="form-label">{t('Streets.areaName')}</label>
                                <STGSingleSelect onChangeHandler={(e)=> setStreet(prev=>{return {...prev,area_id: e.target.value}})} 
                                    options={areasOptions} valueField="id" labelField="name" defaultValue={street.area_id}
                                />
                            </div>
                        </form>
                        <button className={`btn w-100 m-auto ${street.name?`btn-primary`:`btn-danger`}`} onClick={addStreet}  type="button"  disabled={!street.name}>
                            {t('Streets.add')}
                        </button>
                    </STGGetPostEntity>
                </Col>
                <Col md={4} className="mb-3">
                    <Form.Group className={''}>
                        <Form.Label>{t('addPerson.building')}</Form.Label>
                        <Form.Control type="text" placeholder={t('addPerson.building')} 
                            value={generalInfoState.building_number} onChange={(e)=> handleGeneralInfo('building_number',e.target.value)}
                        />
                    </Form.Group>
                </Col>
                <Col md={4} className="mb-3">
                    <Form.Group className={''}>
                        <Form.Label>{t('addPerson.floor')}</Form.Label>
                        <Form.Control type="text" placeholder={t('addPerson.floor')} 
                            value={generalInfoState.floor_number} onChange={(e)=> handleGeneralInfo('floor_number',e.target.value)}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={4} className="mb-3">
                    <Form.Group className={''}>
                        <Form.Label>{t('addPerson.apartment')}</Form.Label>
                        <Form.Control type="text" placeholder={t('addPerson.apartment')} 
                            value={generalInfoState.apartment_number} onChange={(e)=> handleGeneralInfo('apartment_number',e.target.value)}
                        />
                    </Form.Group>
                </Col>
                <Col md={4} className="mb-3">
                    <Form.Group className={''}>
                        <Form.Label>{t('addPerson.description')}</Form.Label>
                        <Form.Control type="text" placeholder={t('addPerson.description')} 
                            value={generalInfoState.description} onChange={(e)=> handleGeneralInfo('description',e.target.value)}
                        />
                    </Form.Group>
                </Col>
                <Col md={4} className="mb-3">
                    <Form.Group className={''}>
                        <Form.Label>{t('addPerson.map')}</Form.Label>
                        <Form.Control type="text" placeholder={t('addPerson.map')} 
                            value={generalInfoState.map} onChange={(e)=> handleGeneralInfo('map',e.target.value)}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <ToastContainer />
        </div>
    );
}
 
export default GeneralInfo;