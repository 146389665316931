import {get,post,deleteRequest,update} from "./index";

export const getAllAreas = async () =>{
    const response = await get("/areas");
    return response;
}
export const createNewArea = async(name) =>{
    const response = await post("/areas/create",{"name":name});
    return response;
}
export const deleteAArea = async(id) => {
    const response = await deleteRequest("/areas/delete",{"id":id});
    return response;
}
export const updateAArea = async({name,id}) =>{
    const response = await update("/areas/update",{"id":id,"name":name});
    return response;
}