import React, { useState, useLayoutEffect } from "react";
import {
  getAllJobs,
  createNewJob,
  deleteAJob,
  updateAJob,
} from "services/jobService";

const useJobCRUD = () => {
  const [showCreateJobModal, setShowCreateJobModal] = useState(false);
  const [showUpdateJobModal, setShowUpdateJobModal] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [newJob, setNewJob] = useState("");
  const [updatedJob, setUpdatedJob] = useState({});
  const [updatedJobName, setUpdatedJobName] = useState("");
  const [clickLoading, setClickLoading] = useState(false);
  
  useLayoutEffect(() => {
    fetchData();
  }, []);
  const handleCreateModalClose = () => {
    setShowCreateJobModal(false);
    setClickLoading(false);
  }
  const handleUpdateModalClose = () => {
    setShowUpdateJobModal(false);
    setClickLoading(false);
  }
  const onOpenCreateModal = () => {
    setShowCreateJobModal(true);
    setClickLoading(false);
  };
  const onOpenUpdateModal = (job) => {
    setClickLoading(false);
    setUpdatedJob(job);
    setUpdatedJobName(job.name);
    setShowUpdateJobModal(true);
  };
  const addJob = async () => {
    setClickLoading(true);
    const response = await createNewJob(newJob);
    handleCreateModalClose();
    fetchData();
    setNewJob("");
  };
  const sendUpdateRequest = async () => {
    setClickLoading(true);
    const response = await updateAJob({ ...updatedJob, name: updatedJobName });
    handleUpdateModalClose();
    fetchData();
    setUpdatedJob({});
    setUpdatedJobName("");
  };
  const deleteHandler = async (id) => {
    const response = await deleteAJob(id);
    fetchData();
  };

  const fetchData = async () => {
    const response = await getAllJobs();
    if (response.status === 200) {
      setJobs(response.data.data);
    }
  };
  const OnChangeUpdateJobHandler = (e) => {
    let tempJobName = e.target.value;
    setUpdatedJobName(tempJobName);
  };

  return {
    jobs,
    onOpenUpdateModal,
    deleteHandler,
    onOpenCreateModal,
    showCreateJobModal,
    handleCreateModalClose,
    setNewJob,
    newJob,
    showUpdateJobModal,
    handleUpdateModalClose,
    updatedJobName,
    OnChangeUpdateJobHandler,
    sendUpdateRequest,
    addJob,
    clickLoading
  };
};
export default useJobCRUD;
