import {get,post,deleteRequest,update} from "./index";

export const getAllPeople = async () =>{
    const response = await get("/persons");
    return response;
}
export const createNewPerson = async(body) =>{
    const response = await post("/persons/create",body);
    return response;
}
export const deleteAPerson = async(id) => {
    const response = await deleteRequest("/persons/delete",{"id":id});
    return response;
}
export const updateAPerson = async(state) =>{
    const response = await update("/persons/update",state);
    return response;
}