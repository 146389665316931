import { BASE_API } from "./config";

const headerObj = {
  "Content-Type": "application/json",
};
class UnauthorizedError extends Error {
  constructor(message) {
    super(message);
    this.code = 401;
  }
}

export const get = async (url) => {
  let accessToken = localStorage.getItem('access_token') || ''
  try {
    return await BASE_API.get(url, {
      responseType: "json",
      headers: {...headerObj, "Content-Language": localStorage.getItem("lang") || "ar", 
       "Authorization": `Bearer ${accessToken}`
      },
    //   timeout: 2000,
    });
  } catch (error) {
      if (error?.response?.status === 401){
        throw new UnauthorizedError(error)
      }
      else {
        throw new Error(error)
      }
  }
};
export const post = async (url, body, responseType = "json") => {
  let accessToken = localStorage.getItem('access_token') || ''
  try {
    return await BASE_API.post(url, body, {
      headers: {...headerObj, "Content-Language": localStorage.getItem("lang") || "ar", 
       "Authorization": `Bearer ${accessToken}`
      },
    //   timeout: 20000,
      responseType: responseType,
    });
  } catch (error) {
      if (error?.response?.status === 401) {
        throw new UnauthorizedError(error)
      }
      else {
        throw new Error(error)
      }
  }
};
export const deleteRequest = async (url,body) => {
  let accessToken = localStorage.getItem('access_token') || ''
  try {
    return await BASE_API.delete(url, {
      headers: {...headerObj, "Content-Language": localStorage.getItem("lang") || "ar", 
       "Authorization": `Bearer ${accessToken}`
      },
    //   timeout: 20000,
      data:body
    });
  } catch (error) {
      if (error?.response?.status === 401){
        throw new UnauthorizedError(error)
      }
      else {
        throw new Error(error)
      }
  }
};
export const update = async (url,body) => {
    let accessToken = localStorage.getItem('access_token') || ''  
    try {
      return await BASE_API.patch(url,body, {
        headers: {...headerObj, "Content-Language": localStorage.getItem("lang") || "ar", 
        "Authorization": `Bearer ${accessToken}`
       },
      //   timeout: 20000,
      });
    } catch (error) {
        if (error?.response?.status === 401){
          throw new UnauthorizedError(error)
        }
        else {
          throw new Error(error)
        }
    }
};

BASE_API.interceptors.response.use(

  (res) => {
    return res;
  },
  async (err) => {

    const originalConfig = err.config;

    if (err.response) {
      console.log("🚀 ~ file: index.js:100 ~ err.response", err.response, originalConfig._retry)
      if ((err?.response?.data?.data === "Unauthenticated.") && !originalConfig._retry) {
        console.log("🚀 ~ file: index.js:101 ~ err?.response?.data?.data", err?.response?.data?.data)
        originalConfig._retry = true;
        let accessToken = localStorage.getItem('access_token') || ''  

        try {
          if (originalConfig.url !== "/refresh") {
            const rs = await BASE_API.post("/refresh", {}, {
              headers: {...headerObj, "Content-Language": localStorage.getItem("lang") || "ar",  
                "Authorization": `Bearer ${accessToken}`
              },
              timeout: 20000,
            });
            console.log("🚀 ~ file: index.js:112 ~ rs", rs)
            return BASE_API(originalConfig);
          }
          else {
            // let logoutRes = await BASE_API.post("/logout", {
              //   headers: {...headerObj, "Content-Language": localStorage.getItem("lang") || "ar",  
              //   "Authorization": `Bearer ${accessToken}`},
              //   timeout: 20000,
              // });
              let lang = localStorage.getItem('Lang') || 'ar'
              localStorage.clear();
              localStorage.setItem('isUserLogged', 'false')
              localStorage.setItem('Lang', lang)
              window.location.replace('/')
          }
        } catch (_error) {
        // console.log("🚀 ~ file: index.js:130 ~ _error", _error)

          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);
