import React, {useState, useEffect} from 'react'
import { useMemo } from 'react';
import Select from 'react-select'

const STGMultiSelect = ({ options, onChangeHandler, selectedValues=[], defaultValue=[]}) => {
    const [selectedPersons, setSelectedPersons] = useState(defaultValue);
    const onChange = (selectedOptions) => {
        setSelectedPersons(selectedOptions);
        onChangeHandler(selectedOptions);
    }
    useEffect(() => {
        if(selectedValues.length ===  0){
            setSelectedPersons([]);
        }
    }, [selectedValues])
    
    let multiSelect = useMemo(() => {
        return( 
            <Select options={options} isMulti getOptionLabel={(option) => option.full_name}
                getOptionValue={(option) => option.id} isRtl value={selectedPersons} onChange={onChange} 
        />
        )
    }
    , [options, selectedValues, defaultValue]);
    return multiSelect;
}

export default STGMultiSelect;