import { Col, Row, Form, InputGroup} from "@themesberg/react-bootstrap";
import STGSingleSelect from "components/STGSingleSelect";
import { useTranslation } from 'react-i18next';
import useFamilyInfo from "./hooks/useFamilyInfo";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
import Datetime from "react-datetime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import STGMultiSelect from "components/STGMultiSelect";

const FamilyInfo = ({familyInfoState, handleFamilyInfo}) => {
    const { t } = useTranslation();
    const { people, peopleOptions } = useFamilyInfo(t)

    return (
        <div className="family-info">
            <Row>
                <Col md={4} className="mb-3">
                    <Form.Group>
                        <Form.Label>{t('addPerson.marriageDate')}</Form.Label>
                        <Datetime
                            timeFormat={false}
                            onChange={(value)=>handleFamilyInfo('marriage_date',value)}
                            renderInput={(props, openCalendar,closeCalendar) => (
                                <InputGroup>
                                    <InputGroup.Text onClick={openCalendar}><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                                    <Form.Control
                                        type="text"
                                        value={familyInfoState.marriage_date}
                                        placeholder="YYYY/MM/DD"
                                        onFocus={openCalendar}
                                        onBlur={closeCalendar}
                                    />
                                </InputGroup>
                            )} 
                        />
                    </Form.Group>
                </Col>
                <Col md={4} className="mb-3">
                    <Form.Group>
                        <Form.Label>{t('addPerson.fatherName')}</Form.Label>
                        <STGSingleSelect onChangeHandler={(e)=> handleFamilyInfo('father_id', parseInt(e.target.value))} 
                            options={peopleOptions} defaultValue={familyInfoState.father_id} labelField='full_name' valueField='id'
                        />
                    </Form.Group>
                </Col>
                <Col md={4} className="mb-3">
                    <Form.Group>
                        <Form.Label>{t('addPerson.motherName')}</Form.Label>
                        <STGSingleSelect onChangeHandler={(e)=> handleFamilyInfo('mother_id', parseInt(e.target.value))} 
                            options={peopleOptions} defaultValue={familyInfoState.mother_id} labelField='full_name' valueField='id'
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6} className="mb-3">
                    <Form.Group>
                        <Form.Label>{t('addPerson.partnerName')}</Form.Label>
                        <STGSingleSelect onChangeHandler={(e)=> handleFamilyInfo('partner_id', parseInt(e.target.value))} 
                            options={peopleOptions} defaultValue={familyInfoState.partner_id} labelField='full_name' valueField='id'
                        />
                    </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                    <Form.Group>
                        <Form.Label>{t('addPerson.brothersIds')}</Form.Label>
                        <STGMultiSelect options={people} 
                            onChangeHandler={(values)=> handleFamilyInfo('brothers_id',values.map(el=>el.id))} 
                            selectedValues={familyInfoState.brothers_id} defaultValue={familyInfoState?.brothers}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <ToastContainer />
        </div>
    );
}
 
export default FamilyInfo;