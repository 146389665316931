import React, { useState, useLayoutEffect } from "react";
import {
  getAllAreas,
  createNewArea,
  deleteAArea,
  updateAArea,
} from "services/areaService";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useAreaCRUD = () => {
  const [showCreateAreaModal, setShowCreateAreaModal] = useState(false);
  const [showUpdateAreaModal, setShowUpdateAreaModal] = useState(false);
  const [clickLoading, setClickLoading] = useState(false);
  const [areas, setAreas] = useState([]);
  const [newArea, setNewArea] = useState("");
  const [updatedArea, setUpdatedArea] = useState({});
  const [updatedAreaName, setUpdatedAreaName] = useState("");
  useLayoutEffect(() => {
    fetchData();
  }, []);
  const handleCreateModalClose = () => {
    setShowCreateAreaModal(false);
    setClickLoading(false);
  }
  const handleUpdateModalClose = () => {
    setShowUpdateAreaModal(false);
    setClickLoading(false);
  }
  const onOpenCreateModal = () => {
    setShowCreateAreaModal(true);
    setClickLoading(false);
  };
  const onOpenUpdateModal = (area) => {
    setUpdatedArea(area);
    setUpdatedAreaName(area.name);
    setShowUpdateAreaModal(true);
    setClickLoading(false);
  };
  const addArea = async () => {
    setClickLoading(true);
    const response = await createNewArea(newArea);
    if(response.data.success){
        toast.success(response.data.message, {
            position: 'top-left',
            autoClose: true,
            icon: "✅"
        });
    }
    else{
        toast.error(response.data.data.name[0], {
            position: 'top-left',
            autoClose: true,
            progress:0.2
        });
    }
    handleCreateModalClose();
    fetchData();
    setNewArea("");
  };
  const sendUpdateRequest = async () => {
    setClickLoading(true);
    const response = await updateAArea({ ...updatedArea, name: updatedAreaName });
    handleUpdateModalClose();
    fetchData();
    setUpdatedArea({});
    setUpdatedAreaName("");
  };
  const deleteHandler = async (id) => {
    const response = await deleteAArea(id);
    fetchData();
  };

  const fetchData = async () => {
    const response = await getAllAreas();
    if (response.status === 200) {
      setAreas(response.data.data);
    }
  };
  const OnChangeUpdateAreaHandler = (e) => {
    let tempAreaName = e.target.value;
    setUpdatedAreaName(tempAreaName);
  };

  return {
    areas,
    onOpenUpdateModal,
    deleteHandler,
    onOpenCreateModal,
    showCreateAreaModal,
    handleCreateModalClose,
    setNewArea,
    newArea,
    showUpdateAreaModal,
    handleUpdateModalClose,
    updatedAreaName,
    OnChangeUpdateAreaHandler,
    sendUpdateRequest,
    addArea,
    clickLoading
  };
};
export default useAreaCRUD;
