
import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { Nav, Image, Navbar, Dropdown, Container } from '@themesberg/react-bootstrap';
import Logo from "assets/img/logo.jpg";
import useNavBar from "customHooks/useNavBar";

export default (props) => {
  const {logoutHandler, meRoutingHandler} = useNavBar();

  return (
    <Navbar variant="dark" expanded className="ps-0 pb-0">
      <Container fluid className="px-0">
        <div className="d-flex w-100 px-0 justify-content-between ">
          <Nav className="px-0">
            <div className="d-flex justify-content-space-between">
              <h1 className="fw-bolder text-primary text-start">كنيسة مارجرجس بخمارويه</h1>
              
            </div>
          </Nav>
           <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center" >
                  <Image src={Logo} className="user-avatar md-avatar rounded-circle" />
                  {/* <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                    <span className="mb-0 font-small fw-bold">Bonnie Green</span>
                  </div> */}
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                <Dropdown.Item className="fw-bold" onClick={meRoutingHandler}>
                  <FontAwesomeIcon icon={faUserCircle} className="me-2" /> بياناتي الشخصية
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item className="fw-bold " onClick={logoutHandler} >
                  <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> تسجيل الخروج
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
        </div>
      </Container>
    </Navbar>
  );
};
