import {get,post,deleteRequest,update} from "./index";

export const getAllStudies = async () =>{
    const response = await get("/studies");
    return response;
}
export const createNewStudy = async(name,parentId) =>{
    const response = await post("/studies/create",{"name":name,"parent_id":parentId});
    return response;
}
export const deleteAStudy = async(id) => {
    const response = await deleteRequest("/studies/delete",{"id":id});
    return response;
}
export const updateAStudy = async(id,name,parentId) =>{
    const response = await update("/studies/update",{"id":id,"name":name,"parent_id":parentId});
    return response;
}
export const getAllStages = async () =>{
    let allParentStudies = [];
    const res = await getAllStudies();
    if(res?.success){
        allParentStudies= res?.data.filter(el=>!el?.parent_id)
    }
    return allParentStudies;
}