import React, { useMemo } from "react";

const STGSingleSelect = ({onChangeHandler , options , classes = "" , defaultValue="", valueField="value",labelField="name"}) => {
  const singleSelect = useMemo(()=>{
    return(
      <select onChange={onChangeHandler} className={`form-select ${classes}`} value={defaultValue}>
          {options.map((el,idx) => {
            return (
              <option key={idx} value={el[valueField]} className="dropdown-option" > {el[labelField]} </option>
            );
          })}
      </select>
    )
  },[defaultValue, options])

  return singleSelect;
};

export default STGSingleSelect;
