import React from "react";
import { Outlet } from "react-router-dom";
import Login from 'pages/Login';
import { Navigate } from 'react-router-dom';
import useAuth from "contexts/useAuth";


const ProtectedRoute = () =>{
    const { auth } = useAuth();
    let isUserLogged = auth.isLogged;  
    
    return (
        <>
            {
              isUserLogged ?
                <Outlet /> 
                // :  <Navigate to="/" replace />
                :  <Login />
            }
        </>
    );
}
export default ProtectedRoute;