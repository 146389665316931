import { useState, useReducer } from 'react';
import { useLocation } from 'react-router-dom';
import moment from "moment-timezone";
import {updateAPerson} from "services/peopleService";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { removeEmptyFields } from "utils/peopleutil";

const useUpdatePerson = (t) =>{
    //states
    const [tab, setTab] = useState(1);
    const [error, setError] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);

    //custom hooks
    const {state} = useLocation();
    let person = state?.person;
    const navigate = useNavigate();

    // methods 
    const retrieveAddresKeyOrEmpty = (key, emptyValue='') =>{
      return (person.addresses[0] && person.addresses[0][key]) ? person.addresses[0][key] : emptyValue;
    }
    const handleGeneralInfo = (key,value) => {
      switch (key) {
        case 'birth_date':
            dispatchGeneral({
                type: "SET_INPUT",
                field: key,
                payload: moment(value).format("YYYY-MM-DD") || ""
            });
          break;
        default:
          dispatchGeneral({
            type: "SET_INPUT",
            field: key,
            payload: value
          });
      }
    }
    const handleAdditionalInfo = (key,value) => {
      switch (key) {
        case 'graduation_year':
            dispatchAdditional({
                type: "SET_INPUT",
                field: key,
                payload: moment(value).format("YYYY-MM-DD") || ""
            });
          break;
        default:
          dispatchAdditional({
            type: "SET_INPUT",
            field: key,
            payload: value
          });
      }
    }
    const handleFamilyInfo = (key,value) => {
      switch (key) {
        case 'marriage_date':
            dispatchFamily({
                type: "SET_INPUT",
                field: key,
                payload: moment(value).format("YYYY-MM-DD") || ""
            });
          break;
        default:
          dispatchFamily({
            type: "SET_INPUT",
            field: key,
            payload: value
          });
      }
    }
    const handleServantInfo = (key,value) => {
      switch (key) {
        case 'servant_graduation_year':
            dispatchServant({
                type: "SET_INPUT",
                field: key,
                payload: moment(value).format("YYYY-MM-DD") || ""
            });
          break;
        default:
          dispatchServant({
            type: "SET_INPUT",
            field: key,
            payload: value
          });
      }
    }
    const sendUpdateRequest = async () =>{
      setModalOpen(false);
      const personState = {
        ...additionalInfoState,
        ...familyInfoState,
        ...servantInfoState,
        id : person?.id
      }
      personState.phones = removeEmptyFields(generalInfoState.phones, 'phone', '');
      personState.emails = [generalInfoState.email];
      personState.addresses = [{
        street_id : generalInfoState.street_id,
        building_number : generalInfoState.building_number,
        floor_number : generalInfoState.floor_number,
        apartment_number : generalInfoState.apartment_number,
        description : generalInfoState.description,
        map : [generalInfoState.map],
      }];
      personState.full_name= generalInfoState.full_name;
      personState.birth_date = generalInfoState.birth_date;
      personState.gender = generalInfoState.gender;
      personState.national_id = generalInfoState.national_id;
      delete personState.brothers;
      const response = await updateAPerson(personState);
      if(response.data.success){
        toast.success(t('ProcesSucceeded'), {
            position: 'top-left',
            autoClose: true,
            icon: "✅"
        });  
        navigate('/', {replace:true});
      }
    else{
        toast.error(response.data.message || t('ProcessFailed'), {
            position: 'top-left',
            autoClose: true,
            progress:0.2
        });
    }
    }
    //constants
    const initialGeneralInfo = {
      full_name: person.full_name,
      birth_date : person.birth_date,
      gender : person.gender,
      national_id :person.national_id,
      email : (person.emails[0] && person.emails[0].email)  ? person.emails[0].email : '',
      phones : person.phones.length > 0 ? person.phones : [{
        "phone_prefix": "",
        "phone": ""
      }],
      street_id : retrieveAddresKeyOrEmpty('street_id', NaN),
      building_number : retrieveAddresKeyOrEmpty('building_number'),
      floor_number : retrieveAddresKeyOrEmpty('floor_number'),
      apartment_number : retrieveAddresKeyOrEmpty('apartment_number'),
      description : retrieveAddresKeyOrEmpty('description'),
      map : retrieveAddresKeyOrEmpty('map'),
    }
    const initialAdditionalInfo = {
      is_died : person.is_died,
      is_priest : person.is_priest,
      is_special : person.is_special,
      is_poor : person.is_poor,
      is_served : person.is_served,
      is_people : person.is_people,
      regularity : person.regularity,
      graduation_year : person.graduation_year,
      marital_status : person.marital_status,
      church_id : person.church_id,
      job_id : person.job_id,
      qualification_id : person.qualification_id,
      study_id : person.study_id,
    }
    const initialFamilyInfo = {
      brothers : person?.person_brothers, 
      father_id : person.father_id, 
      mother_id : person.mother_id, 
      partner_id : person.partner_id, 
      marriage_date : person.marriage_date,
      brothers_id : person?.person_brothers?.map(el => el.id), 
    }
    const initialServantInfo = {
      is_servant : person.is_servant,
      additional_studies : person?.servant_information?.additional_studies || "",
      special_gifts : person?.servant_information?.special_gifts || "",
      graduation_class_reps : person?.servant_information?.graduation_class_reps || "",
      additional_services_out : person?.servant_information?.additional_services_out || "",
      servant_graduation_year : person?.servant_information?.servant_graduation_year || "",
    }
    // reducers 
    const reducer = (state,action) =>{
      switch (action.type) {
        case "SET_INPUT":
          return {...state, [action.field]:action.payload}
        case "RESET":
          return {...initialGeneralInfo}
        default: 
          return state;
      }
    }
    const reducerAdditional = (state,action) =>{
      switch (action.type) {
      case "SET_INPUT":
          return {...state, [action.field]:action.payload}
      case "RESET":
          return {...initialAdditionalInfo}
      default: 
          return state;
      }
    }
    const reducerFamily = (state,action) =>{
      switch (action.type) {
      case "SET_INPUT":
          return {...state, [action.field]:action.payload}
      case "RESET":
          return {...initialFamilyInfo}
      default: 
          return state;
      }
    }
    const reducerServant = (state,action) =>{
      switch (action.type) {
      case "SET_INPUT":
          return {...state, [action.field]:action.payload}
      case "RESET":
          return {...initialServantInfo}
      default: 
          return state;
      }
    }
    //reducers 
    const [generalInfoState, dispatchGeneral] = useReducer(reducer, initialGeneralInfo);
    const [additionalInfoState, dispatchAdditional] = useReducer(reducerAdditional, initialAdditionalInfo);
    const [familyInfoState, dispatchFamily] = useReducer(reducerFamily, initialFamilyInfo);
    const [servantInfoState, dispatchServant] = useReducer(reducerServant, initialServantInfo);

    return { 
      person, tab, setTab, 
      error, setError,
      handleGeneralInfo, generalInfoState, 
      additionalInfoState, handleAdditionalInfo,
      familyInfoState, handleFamilyInfo, 
      servantInfoState, handleServantInfo,
      modalOpen, setModalOpen,
      sendUpdateRequest
    }
}
export default useUpdatePerson;