import React from "react";
import {
  Modal,
} from "@themesberg/react-bootstrap";
import useChurchCRUD from "customHooks/useChurchCRUD";
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
import STGButton from "components/STGButton";

const Churches = () => {
  const { t } = useTranslation();
  const {onOpenCreateModal,
    showCreateModal,
    setShowCreateModal,
    handleCreateModalClose,
    newChurchState,
    handleInputField,
    addChurch,
    churches,
    deleteHandler,
    onOpenUpdateModal,
    handleUpdateModalClose,
    showUpdateModal,
    updatedChurch,
    OnChangeHandler,
    sendUpdateRequest,
    clickLoading
  } = useChurchCRUD();

  return (
    <>
      <div className="container px-0 py-2">
        <section>
          <div className="d-flex align-items-start">
            <h2>{t('Churches.listOfchurches')}</h2>
            <button className="btn btn-primary btn-sm jobs__add-button" onClick={onOpenCreateModal}>
                {t('Churches.add')}
            </button>
          </div>
          <hr />
          <div className="row" style={{overflowY: "hidden"}}>
           {churches.length>0 ?
           <div className="table-responsive">
              <table className="w-75 table table-striped table-bordered table-hover">
                <thead className="px-0 bg-info">
                  <tr>
                    <th className="">
                      <h5>{t('Churches.name')} </h5>
                    </th>
                    <th className="">
                      <h5>{t('Churches.Actions')} </h5>
                    </th>
                  </tr>
                  <tr>
                  </tr>
                </thead>
                <tbody>
                  {churches.map((church, index) => (
                      <tr key={index}>
                        <td className="">
                            <span className="h6">{church.name}</span>
                        </td>
                        <td className="">
                            <button  className="btn btn-primary"  onClick={()=>onOpenUpdateModal(church)} >
                            {t('Churches.update')}
                            </button>
                            <button className="mx-2 btn btn-danger"  onClick={()=>deleteHandler(church.id)} >
                            {t('Churches.delete')}
                            </button>
                        </td>
                      </tr>
                  ))}
                </tbody>
              </table>
           </div>
            :
            <h2 className="text-danger">{t('Streets.Noitems')}</h2>
           } 
          </div>
        </section>

        {/* Add new chruch modal  */}
        <section>   
          <Modal
            as={Modal.Dialog}
            centered
            show={showCreateModal}
            onHide={handleCreateModalClose}
          >
            <Modal.Header>
              <Modal.Title className="h6">{t('Churches.add')}</Modal.Title>
              <button
                type="button" className="btn btn-close modalCloseButton"
                aria-label="Close"
                onClick={handleCreateModalClose}
              />
            </Modal.Header>
            <Modal.Body>
              <div className="churches__modal__body">
                <form id="streetName" className="">
                  <div className="">
                    <label className="form-label">{t('Churches.name')}</label>
                    <input
                      required
                      className="form-control"
                      type="text"
                      placeholder={t('Churches.enter')}
                      value={newChurchState.name}
                      name="name"
                      maxLength='200'
                      onChange={handleInputField}
                    />
                  </div>
                </form>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <STGButton classes="w-100 m-auto" loading={clickLoading} t={t} onCLickHandler={addChurch} disabled={!newChurchState.name}>
                {t('Churches.add')}
              </STGButton>
            </Modal.Footer>
          </Modal>
        </section>

        {/* Update chruch modal  */}
        <section>
        <Modal
            as={Modal.Dialog}
            centered
            show={showUpdateModal}
            onHide={handleUpdateModalClose}
          >
            <Modal.Header>
              <Modal.Title className="h6">{t('Churches.updateCurrent')}</Modal.Title>
              <button
                aria-label="Close"
                type="button" className="btn btn-close modalCloseButton"
                onClick={handleUpdateModalClose}
              />
            </Modal.Header>
            <Modal.Body>
              <div className="col-lg-8 col-md-12">
                <form id="jobNameUpdated">
                  <label className="form-label">{t('Churches.churchName')}</label>
                  <input
                    required
                    className="form-control"
                    type="text"
                    placeholder={t('Jobs.enter')}
                    value={updatedChurch.name}
                    onChange={OnChangeHandler}
                  />
                </form>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <STGButton classes="w-100 m-auto" loading={clickLoading} t={t} onCLickHandler={sendUpdateRequest} disabled={!updatedChurch.name}>
                {t('Churches.updateCurrent')}
              </STGButton>
            </Modal.Footer>
          </Modal>
        </section>
        <ToastContainer />
      </div>
    </>
  );
};
export default Churches;