import 'react-toastify/dist/ReactToastify.css';
import {logout} from "services/authService";
import { useNavigate } from 'react-router-dom';
import useAuth from "contexts/useAuth";

const useNavBar = () => {
    const navigate = useNavigate();
    const { auth,setAuth } = useAuth();
    const logoutHandler = async () =>{
            try {
                const response = await logout();
                if (response.status === 200) {
                    localStorage.setItem("isUserLogged","false");
                    localStorage.setItem("access_token","");
                    setAuth({token: "", isLogged: false})
                    return navigate('/', {replace:true});
                }
            } catch (error) {
                const { response } = error;
                return response;
            }
    }
    const meRoutingHandler = () =>{
        return navigate('/person/detail', {state: {person : JSON.parse(localStorage.getItem("my_data"))}});
    }
    return {logoutHandler, meRoutingHandler}
};
export default useNavBar;
