const STGButton = ({t, classes, disabled, loading, children, onCLickHandler}) => {
    let btnClasses = `${classes} btn ${(disabled && !loading) ? 'btn-danger' : 'btn-primary'} ${loading ? 'loading-button' : ''}`;
    document.onkeydown = (e) => enter(e);
    function enter(e) {
        if ((e.key === 'Enter' || e.keyCode === 13) && !disabled && !loading) {
          e.preventDefault();
          onCLickHandler();
        }
    }
    return ( 
        <button className={btnClasses} disabled={disabled} onClick={(loading || disabled)? undefined : onCLickHandler}>
            {loading ? t('loading')  :children}
        </button>
     );
}
 
export default STGButton;