import React, { useState, useEffect} from "react";
import { Outlet } from "react-router-dom";

// components
import Sidebar from "components/Sidebar";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import Preloader from "components/Preloader";

const RouteWithSidebar = () => {
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => setLoaded(true), 1000);
        return () => clearTimeout(timer);
    }, []);

    const localStorageIsSettingsVisible = () => {
        return localStorage.getItem('settingsVisible') === 'false' ? false : true
    }

    const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

    const toggleSettings = () => {
        setShowSettings(!showSettings);
        localStorage.setItem('settingsVisible', !showSettings);
    }

    return (
        <>
            <Preloader show={loaded ? false : true} />
            <Sidebar />

            <main className="content bg-img">
                <div className="bg-layer">
                    <div className="p-3">
                        <Navbar />
                        <Outlet />
                        <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
                    </div>
                </div>
            </main>
        </>
    );
};
export default RouteWithSidebar;
