import {get,post,deleteRequest,update} from "./index";

export const getAllStreets = async () =>{
    const response = await get("/streets");
    return response;
}
export const createNewStreet = async(name,areaId) => {
    const response = await post("/streets/create",{"name":name,"area_id":areaId});
    return response;
}
export const deleteAStreet = async(id) => {
    const response = await deleteRequest("/streets/delete",{"id":id});
    return response;
}
export const updateAStreet = async(id,name,areaId) => {
    const response = await update("/streets/update",{"id":id,"name":name,"area_id":areaId});
    return response;
}