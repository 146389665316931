import React from "react";
import {
  Col, Row, Card, Form, Button, InputGroup
} from "@themesberg/react-bootstrap";
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
import Datetime from "react-datetime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import useAddPerson from "customHooks/useAddPerson";
import STGSingleSelect from "components/STGSingleSelect";
import STGGetPostEntity from "components/STGGetPostEntity";
import STGMultiSelect from "components/STGMultiSelect";
import STGPhone from 'components/STGPhone/STGPhone';

const AddPerson = () => {
    const { t } = useTranslation();
    const {
        personState , maritalStatusOptions , genderOptions, handlePersonStateChange, errorFieldsState, regularityOptions, handleYesOrNoFields,
        yesOrNoOptions, churches, churchName,setChurchName,addChurch,disabledAddChurch , jobs, jobName, setJobName, 
        addJob, disabledAddjob, handleIdFields,  qualifications, qualificationName, setQualificationName, disabledAddQualification,
        addQualification, studies, study, setStudy , addٍStudy, disabledAddStudy ,addPerson, 
        handleStreetStateChange, streetState, streets, street, setStreet, addStreet, disabledAddStreet, areas, handleServiceStateChange,
        serviceState, people, setRelationIds,relationIds, handleRelationIds, brothers_id, handlePhoneError
    } = useAddPerson();
        const churchOptions = [{id:NaN,name:t('Choose')},...churches];
        const jobOptions = [{id:NaN,name:t('Choose')},...jobs];
        const qualificationOptions = [{id:NaN,name:t('Choose')},...qualifications];
        const studyOptions = [{id:NaN,name:t('Choose')},...studies];
        const streetOptions = [{id:NaN,name:t('Choose')}, ...streets];
        const areasOptions = [{id:NaN,name:t('Choose')}, ...areas];
        const peopleOptions = [{id:NaN,full_name:t('Choose')}, ...people];
        const hasError = (errorFieldsState?.fullName?.hasError || errorFieldsState?.nationalId?.hasError || errorFieldsState?.email?.hasError || errorFieldsState?.phone?.hasError )

    return (
        <>
            <Card className="add-person__card shadow-sm ">
                <Card.Body>
                   <Form>
                    {/* main data section  */}
                    <h4 className="mb-4">{t('addPerson.generalInfo')}</h4>
                    <Row>
                        <Col md={6} className="mb-3">
                            <Form.Group id="firstName" className={ errorFieldsState?.fullName?.hasError ? 'error-field' : ''}>
                                <Form.Label>{t('addPerson.fullName')} *</Form.Label>
                                <Form.Control required type="text" placeholder={t('addPerson.enterFullName')} name={'full_name'} value={personState.full_name} onChange={(e)=>handlePersonStateChange(e,'full_name')}/>
                                <div className="error-message">
                                    <ul>
                                        {errorFieldsState?.fullName?.errorMessage.map((error,idx)=>
                                            <li key={idx}>{error}</li>
                                        )}
                                    </ul>
                                </div>
                            </Form.Group>
                        </Col>
                        <Col md={6} className="mb-3">
                            <Form.Group id="birthday">
                                <Form.Label>{t('addPerson.birthday')}</Form.Label>
                                <Datetime
                                timeFormat={false}
                                onChange={(e)=>handlePersonStateChange(e,"birth_date")}
                                renderInput={(props, openCalendar,closeCalendar) => (
                                    <InputGroup>
                                    <InputGroup.Text onClick={openCalendar}><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                                    <Form.Control
                                        type="text"
                                        value={personState.birth_date}
                                        placeholder="YYYY/MM/DD"
                                        onFocus={openCalendar}
                                        onBlur={closeCalendar}
                                    />
                                    </InputGroup>
                                )} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col md={6} className="mb-3">
                            <Form.Group id="gender">
                                <Form.Label>{t('addPerson.gender')}</Form.Label>
                                <STGSingleSelect onChangeHandler={(e)=> handlePersonStateChange(e,'gender')} options={genderOptions} 
                                    defaultValue={personState.gender} 
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6} className="mb-3">
                            <Form.Group id="maritalStatus">
                                <Form.Label>{t('addPerson.maritalStatus')}</Form.Label>
                                <STGSingleSelect onChangeHandler={(e)=> handlePersonStateChange(e,'marital_status')} options={maritalStatusOptions} 
                                    defaultValue={personState.marital_status} 
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} className="mb-3">
                            <Form.Group id="marriageDate">
                                    <Form.Label>{t('addPerson.marriageDate')}<span className="mx-2">{t('ifFound')}</span></Form.Label>
                                    <Datetime
                                    timeFormat={false}
                                    onChange={(e)=>handlePersonStateChange(e,"marriage_date")}
                                    renderInput={(props, openCalendar, closeCalendar) => (
                                        <InputGroup>
                                        <InputGroup.Text onClick={openCalendar}><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                                        <Form.Control
                                            type="text"
                                            value={personState.marriage_date}
                                            placeholder="YYYY/MM/DD"
                                            onFocus={openCalendar}
                                            onBlur={closeCalendar}
                                        />
                                        </InputGroup>
                                    )} />
                            </Form.Group>
                        </Col>
                        <Col md={6} className="mb-3">
                            <Form.Group id="nationalId" className={ errorFieldsState?.nationalId?.hasError ? 'error-field' : ''}>
                                <Form.Label>{t('addPerson.nationalId')}</Form.Label>
                                <Form.Control type="text" placeholder={t('addPerson.nationalId')} 
                                    value={personState.national_id} onChange={(e)=>handlePersonStateChange(e,'national_id')}
                                />
                                <div className="error-message">
                                    <ul>
                                        {errorFieldsState?.nationalId?.errorMessage.map((error,idx)=>
                                            <li key={idx}>{error}</li>
                                        )}
                                    </ul>
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} className="mb-3">
                            <Form.Group id="graduationYear">
                                    <Form.Label>{t('addPerson.graduationYear')}</Form.Label>
                                    <Datetime
                                    timeFormat={false}
                                    onChange={(e)=>handlePersonStateChange(e,"graduation_year")}
                                    renderInput={(props, openCalendar, closeCalendar) => (
                                        <InputGroup>
                                        <InputGroup.Text onClick={openCalendar}><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                                        <Form.Control
                                            type="text"
                                            value={personState.graduation_year}
                                            placeholder="YYYY/MM/DD"
                                            onFocus={openCalendar}
                                            onBlur={closeCalendar}
                                        />
                                        </InputGroup>
                                    )} />
                            </Form.Group>
                        </Col>
                        <Col md={6} className="mb-3">
                            <Form.Group id="regularity">
                                <Form.Label>{t('addPerson.regularity')}</Form.Label>
                                <STGSingleSelect onChangeHandler={(e)=> handlePersonStateChange(e,'regularity')} 
                                    options={regularityOptions} defaultValue={personState.regularity}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} className="mb-3">
                            <Form.Group id="email" className={ errorFieldsState?.email?.hasError ? 'error-field' : ''}>
                                <Form.Label>{t('addPerson.addEmail')}</Form.Label>
                                <Form.Control type="email" placeholder={t('addPerson.addEmail')} name={'email'} value={personState.email} onChange={(e)=>handlePersonStateChange(e,'email')}/>
                                <div className="error-message">
                                    <ul>
                                        {errorFieldsState?.email?.errorMessage.map((error,idx)=>
                                            <li key={idx}>{error}</li>
                                        )}
                                    </ul>
                                </div>
                            </Form.Group>
                        </Col>
                        <Col md={6} className="mb-3">
                            <STGPhone 
                                initialValue={personState.phones} 
                                onChangeHandler={(phones) => handlePersonStateChange(phones, 'phones')}
                                handlePhoneError={handlePhoneError}
                            />
                        </Col>
                    </Row>

                    {/* yes or no section  */}
                    <h4 className="my-2">{t('yesOrNo')}</h4>
                    <Row>
                        <Col md={4} className="mb-3">
                            <Form.Group id="isDied">
                                <Form.Label>{t('addPerson.isDied')}</Form.Label>
                                <STGSingleSelect onChangeHandler={(e)=> handleYesOrNoFields(e,'is_died')} 
                                    options={yesOrNoOptions} defaultValue={personState.is_died}/>
                            </Form.Group>
                        </Col>
                        <Col md={4} className="mb-3">
                            <Form.Group id="is_servant">
                                <Form.Label>{t('addPerson.isServant')}</Form.Label>
                                <STGSingleSelect onChangeHandler={(e)=> handleYesOrNoFields(e,'is_servant')} 
                                    options={yesOrNoOptions} defaultValue={personState.is_servant}/>
                            </Form.Group>
                        </Col>
                        <Col md={4} className="mb-3">
                            <Form.Group id="is_priest">
                                <Form.Label>{t('addPerson.isPriest')}</Form.Label>
                                <STGSingleSelect onChangeHandler={(e)=> handleYesOrNoFields(e,'is_priest')} 
                                    options={yesOrNoOptions} defaultValue={personState.is_priest}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3} className="mb-3">
                            <Form.Group id="is_poor">
                                <Form.Label>{t('addPerson.isPoor')}</Form.Label>
                                <STGSingleSelect onChangeHandler={(e)=> handleYesOrNoFields(e,'is_poor')} 
                                    options={yesOrNoOptions} defaultValue={personState.is_poor} />
                            </Form.Group>
                        </Col>
                        <Col md={3} className="mb-3">
                            <Form.Group id="is_special">
                                <Form.Label>{t('addPerson.isSpecial')}</Form.Label>
                                <STGSingleSelect onChangeHandler={(e)=> handleYesOrNoFields(e,'is_special')} 
                                    options={yesOrNoOptions} defaultValue={personState.is_special}/>
                            </Form.Group>
                        </Col>
                        <Col md={3} className="mb-3">
                            <Form.Group id="is_served">
                                <Form.Label>{t('addPerson.isServed')}</Form.Label>
                                <STGSingleSelect onChangeHandler={(e)=> handleYesOrNoFields(e,'is_served')} 
                                    options={yesOrNoOptions} defaultValue={personState.is_served}/>
                            </Form.Group>
                        </Col>
                        <Col md={3} className="mb-3">
                            <Form.Group id="is_people">
                                <Form.Label>{t('addPerson.isPeople')}</Form.Label>
                                <STGSingleSelect onChangeHandler={(e)=> handleYesOrNoFields(e,'is_people')} 
                                    options={yesOrNoOptions} defaultValue={personState.is_people}/>
                            </Form.Group>
                        </Col>
                    </Row>

                    {/* relations section  */}
                    <h4 className="my-2">{t('addPerson.withRelations')}</h4>
                    <Row>
                        <Col md={6} className="mb-3">
                            <STGGetPostEntity valueField="id" labelField="name" label={t('ChooseChurch')} id="church" onChangeDropDown={(e)=> handleIdFields(e.target.value,'church_id')} 
                             options={churchOptions} disableAdd={disabledAddChurch} defaultValue={personState.church_id}
                            >
                                <form id="churchName" className="">
                                    <label className="form-label">{t('Churches.name')}</label>
                                    <input
                                    className="form-control"
                                    type="text"
                                    placeholder={t('Churches.enter')}
                                    value={churchName}
                                    name="name"
                                    maxLength='200'
                                    onChange={e=>setChurchName(e.target.value)}
                                    />
                                    <button className={`btn w-100 m-auto ${churchName?`btn-primary`:`btn-danger`}`} type="button" onClick={addChurch}  disabled={!churchName}>
                                        {t('Churches.add')}
                                    </button>
                                </form>
                            </STGGetPostEntity>
                        </Col>
                        <Col md={6} className="mb-3">
                            <STGGetPostEntity valueField="id" labelField="name" label={t('ChooseJob')} id="job" onChangeDropDown={(e)=> handleIdFields(e.target.value,'job_id')} 
                             options={jobOptions} disableAdd={disabledAddjob} defaultValue={personState.job_id}
                            >
                                <form id="churchName" className="">
                                    <label className="form-label">{t('Jobs.name')}</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder={t('Jobs.enter')}
                                        value={jobName}
                                        name="name"
                                        maxLength='200'
                                        onChange={e=>setJobName(e.target.value)}
                                    />
                                    <button className={`btn w-100 m-auto ${jobName?`btn-primary`:`btn-danger`}`} type="button" onClick={addJob}  disabled={!jobName}>
                                        {t('Jobs.add')}
                                    </button>
                                </form>
                            </STGGetPostEntity>

                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} className="mb-3">
                            <STGGetPostEntity valueField="id" labelField="name" label={t('ChooseQ')} id="qualification" onChangeDropDown={(e)=> handleIdFields(e.target.value,'qualification_id')} 
                             options={qualificationOptions} disableAdd={disabledAddQualification}  defaultValue={personState.qualification_id}
                            >
                                <form id="qualificationName" className="">
                                    <label className="form-label">{t('Qualifications.name')}</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder={t('Qualifications.enter')}
                                        value={qualificationName}
                                        name="name"
                                        maxLength='200'
                                        onChange={e=>setQualificationName(e.target.value)}
                                    />
                                    <button className={`btn w-100 m-auto ${qualificationName?`btn-primary`:`btn-danger`}`} type="button" onClick={addQualification}  disabled={!qualificationName}>
                                        {t('Qualifications.add')}
                                    </button>
                                </form>
                            </STGGetPostEntity>
                        </Col>
                        <Col md={6} className="mb-3">
                            <STGGetPostEntity valueField="id" labelField="name" label={t('ChooseStudy')} id="study" 
                             onChangeDropDown={(e)=> handleIdFields(e.target.value,'study_id')} 
                             options={studyOptions} disableAdd={disabledAddStudy} defaultValue={personState.study_id}
                            >
                                <form id="studyName">
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <label className="form-label">{t('Studies.studyName')}</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder={t('Studies.enter')}
                                                value={study.name}
                                                name="name"
                                                maxLength='200'
                                                onChange={e=>setStudy(prev=>{return {...prev,name:e.target.value}})}
                                            />
                                        </div>
                                        <div className="">
                                            <label className="form-label">{t('Studies.stageName')}</label>
                                            <STGSingleSelect onChangeHandler={(e)=> setStudy(prev=>{return {...prev,parentId:e.target.value}})} options={studyOptions.filter(el=>!el?.parent_id)} 
                                                valueField="id" labelField="name" defaultValue={study.parentId}/>
                                        </div>
                                    </div>
                                    <button className={`btn w-100 m-auto ${study.name?`btn-primary`:`btn-danger`}`} onClick={addٍStudy}  type="button"  disabled={!study.name}>
                                        {t('Studies.add')}
                                    </button>
                                </form>
                            </STGGetPostEntity>
                        </Col>
                    </Row>

                    {/* address section  */}
                    <h4 className="my-2">{t('addPerson.address')}</h4>
                    <Row>
                        <Col md={4} className="mb-3">
                            <STGGetPostEntity valueField="id" labelField="name" label={t('addPerson.streetChoose')} id="street" 
                             onChangeDropDown={(e)=> handleStreetStateChange(e.target.value,'street_id')} 
                             options={streetOptions} disableAdd={disabledAddStreet} defaultValue={streetState.street_id}
                            >
                                <form id="streetName" className="d-flex justify-content-between">
                                    <div>
                                        <label className="form-label">{t('Streets.name')}</label>
                                        <input
                                            required
                                            className="form-control"
                                            type="text"
                                            placeholder={t('Streets.enter')}
                                            value={street.name}
                                            name="street"
                                            onChange={e=>setStreet(prev=>{return {...prev,name:e.target.value}})}
                                        />
                                    </div>
                                    <div className="">
                                        <label className="form-label">{t('Streets.areaName')}</label>
                                        <STGSingleSelect onChangeHandler={(e)=> setStreet(prev=>{return {...prev,area_id: e.target.value}})} 
                                            options={areasOptions} valueField="id" labelField="name" defaultValue={street.area_id}
                                        />
                                    </div>
                                </form>
                                <button className={`btn w-100 m-auto ${street.name?`btn-primary`:`btn-danger`}`} onClick={addStreet}  type="button"  disabled={!street.name}>
                                    {t('Streets.add')}
                                </button>
                            </STGGetPostEntity>
                        </Col>
                        <Col md={4} className="mb-3">
                            <Form.Group className={''}>
                                <Form.Label>{t('addPerson.building')}</Form.Label>
                                <Form.Control type="text" placeholder={t('addPerson.building')} 
                                    value={streetState.building_number} onChange={(e)=> handleStreetStateChange(e.target.value,'building_number')}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={4} className="mb-3">
                            <Form.Group className={''}>
                                <Form.Label>{t('addPerson.floor')}</Form.Label>
                                <Form.Control type="text" placeholder={t('addPerson.floor')} 
                                    value={streetState.floor_number} onChange={(e)=> handleStreetStateChange(e.target.value,'floor_number')}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4} className="mb-3">
                            <Form.Group className={''}>
                                <Form.Label>{t('addPerson.apartment')}</Form.Label>
                                <Form.Control type="text" placeholder={t('addPerson.apartment')} 
                                    value={streetState.apartment_number} onChange={(e)=> handleStreetStateChange(e.target.value,'apartment_number')}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={4} className="mb-3">
                            <Form.Group className={''}>
                                <Form.Label>{t('addPerson.description')}</Form.Label>
                                <Form.Control type="text" placeholder={t('addPerson.description')} 
                                    value={streetState.description} onChange={(e)=> handleStreetStateChange(e.target.value,'description')}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={4} className="mb-3">
                            <Form.Group className={''}>
                                <Form.Label>{t('addPerson.map')}</Form.Label>
                                <Form.Control type="text" placeholder={t('addPerson.map')} 
                                    value={streetState.map} onChange={(e)=> handleStreetStateChange(e.target.value,'map')}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    
                    {/* Service section  */}
                    {personState.is_servant ? 
                    <>
                        <h4 className="my-2 servant-header">{t('addPerson.servant')}</h4>
                        <Row>
                            <Col md={4} className="mb-3">
                                <Form.Group className={''}>
                                    <Form.Label>{t('addPerson.additionalStudies')}</Form.Label>
                                    <Form.Control type="text" placeholder={t('addPerson.additionalStudies')} 
                                        value={serviceState.additional_studies} onChange={(e)=> handleServiceStateChange(e,'additional_studies')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4} className="mb-3">
                                <Form.Group className={''}>
                                    <Form.Label>{t('addPerson.gift')}</Form.Label>
                                    <Form.Control type="text" placeholder={t('addPerson.gift')} 
                                        value={serviceState.special_gifts} onChange={(e)=> handleServiceStateChange(e,'special_gifts')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4} className="mb-3">
                                <Form.Group>
                                    <Form.Label>{t('addPerson.gradYear')}</Form.Label>
                                    <Datetime
                                        timeFormat={false}
                                        onChange={(e)=>handleServiceStateChange(e,"servant_graduation_year")}
                                        renderInput={(props, openCalendar,closeCalendar) => (
                                            <InputGroup>
                                            <InputGroup.Text onClick={openCalendar}><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                value={serviceState.servant_graduation_year}
                                                placeholder="YYYY/MM/DD"
                                                onFocus={openCalendar}
                                                onBlur={closeCalendar}
                                            />
                                        </InputGroup>
                                    )} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} className="mb-3">
                                <Form.Group className={''}>
                                    <Form.Label>{t('addPerson.gradClassRep')}</Form.Label>
                                    <Form.Control type="text" placeholder={t('addPerson.gradClassRep')} 
                                        value={serviceState.graduation_class_reps} onChange={(e)=> handleServiceStateChange(e,'graduation_class_reps')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6} className="mb-3">
                                <Form.Group className={''}>
                                    <Form.Label>{t('addPerson.addServicesOut')}</Form.Label>
                                    <Form.Control type="text" placeholder={t('addPerson.addServicesOut')} 
                                        value={serviceState.additional_services_out} onChange={(e)=> handleServiceStateChange(e,'additional_services_out')}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </>
                    : null
                    }

                    {/* one family section  */}
                    <h4 className="my-2">{t('addPerson.oneFamily')}</h4>
                    <Row>
                        <Col md={6} className="mb-3">
                            <Form.Group className={''}>
                                <Form.Label>{t('addPerson.fatherName')}</Form.Label>
                                <STGSingleSelect onChangeHandler={(e)=> handleRelationIds(e.target.value,'father_id')} options={peopleOptions} 
                                    valueField="id" labelField="full_name" defaultValue={relationIds.father_id}/>
                            </Form.Group>
                        </Col>
                        <Col md={6} className="mb-3">
                            <Form.Group className={''}>
                                <Form.Label>{t('addPerson.motherName')}</Form.Label>
                                <STGSingleSelect onChangeHandler={(e)=> handleRelationIds(e.target.value,'mother_id')} options={peopleOptions}
                                    valueField="id" labelField="full_name" defaultValue={relationIds.mother_id}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} className="mb-3">
                            <Form.Group className={''}>
                                <Form.Label>{t('addPerson.partnerName')}</Form.Label>
                                <STGSingleSelect onChangeHandler={(e)=>  handleRelationIds(e.target.value,'partner_id')} options={peopleOptions}
                                    valueField="id" labelField="full_name" defaultValue={relationIds.partner_id}/>
                            </Form.Group>
                        </Col>
                        <Col md={6} className="mb-3">
                            <Form.Group className={''}>
                                <Form.Label>{t('addPerson.brothersIds')}</Form.Label>
                                <STGMultiSelect options={people} 
                                    onChangeHandler={(values)=> handleRelationIds(values,'brothers_id')} 
                                    selectedValues={brothers_id}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mt-2">
                        <Col md={12} className="">
                            <button className={`btn w-100 m-auto ${!(hasError || personState.full_name === "")?`btn-primary`:`btn-danger`}`} onClick={addPerson}  type="button"  disabled={(hasError || personState.full_name === "")}>
                                {t('SaveChanges')}
                            </button>
                        </Col>
                    </Row>
                   </Form>
                </Card.Body>
            </Card>
            <ToastContainer />
        </>
    );
};
export default AddPerson;