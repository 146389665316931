import {get,post,deleteRequest,update} from "./index";

export const getAllJobs = async () =>{
    const response = await get("/jobs");
    return response;
}
export const createNewJob = async(name) =>{
    const response = await post("/jobs/create",{"name":name});
    return response;
}
export const deleteAJob = async(id) => {
    const response = await deleteRequest("/jobs/delete",{"id":id});
    return response;
}
export const updateAJob = async({name,id}) =>{
    const response = await update("/jobs/update",{"id":id,"name":name});
    return response;
}