import React from "react";
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import useGetAllPeople from "customHooks/useGetAllPeople";
import { Link } from 'react-router-dom';


const PeopleList = () => {
    const { t } = useTranslation();
    const {people, deleteHandler, loading} = useGetAllPeople();
    return (
      <div className="container px-0 py-2">
          <section>
            <div className="d-flex align-items-start">
              <h2>{t('routing.People')}</h2>
            </div>
            <hr />
            <div className="row" style={{overflowY: "hidden"}}>
           {loading ? <h3 className="text-danger">{'تحميل ....'}</h3> :
              people.length>0  ?
                  <div className="table-responsive">
                      <table className="w-75 table table-striped table-bordered table-hover">
                        <thead className="px-0 bg-info">
                          <tr>
                            <th className="">
                              <h5>{t('addPerson.fullName')} </h5>
                            </th>
                            <th className="">
                              <h5>{t('addPerson.birthday')} </h5>
                            </th>
                            <th className="">
                              <h5>{t('addPerson.gender')} </h5>
                            </th>
                            <th className="">
                              <h5>{t('addPerson.maritalStatus')} </h5>
                            </th>
                            <th className="">
                              <h5>{t('addPerson.regularity')} </h5>
                            </th>
                            <th className="">
                              <h5>{t('Streets.Actions')} </h5>
                            </th>
                          </tr>
                          <tr>
                          </tr>
                        </thead>
                        <tbody>
                          {people.map((person, index) => (
                              <tr key={index}>
                                <td className="">
                                    <span className="h6">{person.full_name}</span>
                                </td>
                                <td className="">
                                    <span className="h6">{person.birth_date || ''}</span>
                                </td>
                                <td className="">
                                    <span className="h6">{person.gender || ''}</span>
                                </td>
                                <td className="">
                                    <span className="h6">{person.marital_status || ''}</span>
                                </td>
                                <td className="">
                                    <span className="h6">{person.regularity || ''}</span>
                                </td>
                                <td className="">
                                    <button className="mx-2 btn btn-danger"  onClick={()=>deleteHandler(person.id)} >
                                      {t('Streets.delete')}
                                    </button>
                                    <Link to={"/person/detail"} state={{ person: person }}>
                                        <button className="mx-2 btn btn-primary"  >
                                            {t("people.detail")}
                                        </button>
                                    </Link>
                                    <Link to={"/person/update"} state={{ person: person }}>
                                        <button className="mx-2 btn btn-info"  >
                                            {t("people.update")}
                                        </button>
                                    </Link>
                                </td>
                              </tr>
                          ))}
                        </tbody>
                      </table>
                  </div>
                    :
                  <h3 className="text-danger">{t('Streets.Noitems')}</h3>
           }

          </div>
          </section>
      </div>
    );
};
export default PeopleList;