import React, {useState} from "react";
import {Form} from "@themesberg/react-bootstrap";
import STGSingleSelect from "components/STGSingleSelect";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const STGGetPostEntity = ({label , id = "" ,onChangeDropDown, options, classes = "" , children, defaultValue="", valueField="value",labelField = "name", disableAdd = false}) => {
  const [isClicked,setIsClicked] = useState(false);
  return (
    <>
      <Form.Group id={id} className={`get-post-entity ${classes}`}>
        <Form.Label>{label}</Form.Label>
        <div className="dropdown-icon__wrapper">
          <STGSingleSelect onChangeHandler={onChangeDropDown}  
            options={options} 
            defaultValue={defaultValue} 
            valueField={valueField} 
            labelField={labelField}
          /> 
          {!disableAdd ?
            <div className="sidebar-icon plus-getPost" onClick={()=>setIsClicked(!isClicked)}>
                <FontAwesomeIcon icon={faPlus} />
            </div>
            :null
          }

        </div>
        {(isClicked && !disableAdd)? <div className="add-form">{children}</div> :null}
      </Form.Group>
    </>
  );
};

export default STGGetPostEntity;
