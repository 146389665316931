import React, { useState, useLayoutEffect,useReducer} from "react";
import {
  getAllStudies,
  createNewStudy,
  deleteAStudy,
  updateAStudy,
} from "services/studyService";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';

const useStudyCRUD = () => {
  const { t } = useTranslation();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(1);
  const [studies, setStudies] = useState([]);
  const [clickLoading, setClickLoading] = useState(false);
  const handleCreateModalClose = () => {
    setShowCreateModal(false);
    setSelectedTab(1);
  }
  const handleUpdateModalClose = () => {
    setShowUpdateModal(false);
    setClickLoading(false);
  }

  const initialStudystate = {
    name: "",
    parentId:null,
    stageName:""
  };
  const initialUpdatedStudystate = {
    id:"",
    name: "",
    parentId:null,
    stageName:""
  };
  const reducer = (state,action) =>{
    switch (action.type) {
      case "SET_INPUT":
        return {
          ...state,
          [action.field]:action.payload
        }
      case "RESET":
        return {
          ...initialStudystate
        }
      default: 
      return state;
    }
  }
  const updatedReducer = (state,action) =>{
    switch (action.type) {
      case "SET_INPUT":
        return {
          ...state,
          [action.field]:action.payload
        }
      case "SET_ALL":
        return {
          ...action.payload
        }
      case "RESET":
        return {
          ...initialUpdatedStudystate
        }
      default: 
        return state;
    }
  }
  const [Studystate, dispatch] = useReducer(reducer, initialStudystate);
  const [updatedStudystate, dispatchUpdatedState] = useReducer(updatedReducer, initialUpdatedStudystate);
  const stages = studies.filter(el=>!el?.parent_id);
  useLayoutEffect(() => {
    fetchData();
  }, []);
  const handleNewStudyNameChange = (e) =>{
    dispatch({
      type: "SET_INPUT",
      field:e.target.name,
      payload:e.target.value
    }) 
  }
  const handleUpdatedStudyNameChange = (e) =>{
    dispatchUpdatedState({
      type: "SET_INPUT",
      field:e.target.name,
      payload:e.target.value
    }) 
  }
  const onOpenCreateModal = () => {
    setShowCreateModal(true);
    setClickLoading(false);
  };
  const onOpenUpdateModal = (Study) => {
    const updatedState = {
      name: Study.name,
      id:Study.id,
      parentId:Study.parent_id,
      stageName: Study?.parent?.name
    }
    dispatchUpdatedState({
      type:"SET_ALL",
      payload:updatedState
    })
    setShowUpdateModal(true);
    setClickLoading(false);
  };
  const addStudy = async () => {
    setClickLoading(true);
    const parentId = selectedTab === 2 ? null : Studystate.parentId;
    const response = await createNewStudy(Studystate?.name,parentId);
    if(response.data.success){
        toast.success(t('ProcesSucceeded'), {
            position: 'top-left',
            autoClose: true,
            icon: "✅"
        });
        handleCreateModalClose();
        fetchData();
        dispatch({type: "RESET"})
    }
    else{
        toast.error(t('ProcessFailed'), {
            position: 'top-left',
            autoClose: true,
            progress:0.2
        });
    }  
  };
  const onChangeStage = (stage,updated=false) =>{
    if(!updated){
      dispatch({
        type: "SET_INPUT",
        field:'parentId',
        payload:stage?.id
      })
      dispatch({
        type: "SET_INPUT",
        field:'stageName',
        payload:stage?.name
      })
    }
    else{
      dispatchUpdatedState({
        type: "SET_INPUT",
        field:'parentId',
        payload:stage?.id
      })
      dispatchUpdatedState({
        type: "SET_INPUT",
        field:'stageName',
        payload:stage?.name
      })
    }
  }
  const sendUpdateRequest = async () => {
    setClickLoading(true);
    const response = await updateAStudy(updatedStudystate?.id,updatedStudystate?.name,updatedStudystate?.parentId);
    if(response?.data?.success){
        toast.success(t('ProcesSucceeded'), {
            position: 'top-left',
            autoClose: true,
            icon: "✅"
        });
        handleUpdateModalClose();
        fetchData();
        dispatchUpdatedState({type: "RESET"})

    }
    else{
        toast.error(t('ProcessFailed'), {
            position: 'top-left',
            autoClose: true,
            progress:0.2
        });
    }
  };
  const deleteHandler = async (id) => {
    const response = await deleteAStudy(id);
    fetchData();
  };

  const fetchData = async () => {
    const response = await getAllStudies();
    if (response.status === 200) {
      setStudies(response?.data?.data);
    }
  };
 
  return {
    studies,
    onOpenUpdateModal,
    deleteHandler,
    onOpenCreateModal,
    showCreateModal,
    handleCreateModalClose,
    showUpdateModal,
    handleUpdateModalClose,
    handleNewStudyNameChange,
    sendUpdateRequest,
    addStudy,
    Studystate,
    deleteHandler,
    selectedTab,
    setSelectedTab,
    stages,
    onChangeStage,
    updatedStudystate,
    handleUpdatedStudyNameChange,
    clickLoading
  };
};
export default useStudyCRUD;
