import {get,post,deleteRequest,update} from "./index";

export const getAllChurches = async () =>{
    const response = await get("/churches");
    return response;
}
export const createNewChurch = async({name}) => {
    const body = 
    {
        "name":name,
    }
    const response = await post("/churches/create",body);
    return response;
}
export const deleteAChurch = async(id) => {
    const response = await deleteRequest("/churches/delete",{"id":id});
    return response;
}
export const updateAChurch = async(church) => {
    const response = await update("/churches/update",{...church});
    return response;
}