import { Col, Row, Card, Form, Button, InputGroup} from "@themesberg/react-bootstrap";
import { useTranslation } from 'react-i18next';
import Datetime from "react-datetime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import STGSingleSelect from "components/STGSingleSelect";
import useServantInfo from "./hooks/useServantInfo";

const ServantInfo = ({servantInfoState, handleServantInfo}) => {
    const { t } = useTranslation();
   const { yesOrNoOptions } = useServantInfo(t);
    return (
        <div className="servant-info">
            <Row>
                <Col md={6} className="mb-3">
                    <Form.Group>
                        <Form.Label>{t('addPerson.isServant')}</Form.Label>
                        <STGSingleSelect onChangeHandler={(e)=> handleServantInfo('is_servant',parseInt(e.target.value))} 
                            options={yesOrNoOptions} defaultValue={servantInfoState.is_servant}/>
                    </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                    <Form.Group>
                        <Form.Label>{t('addPerson.additionalStudies')}</Form.Label>
                        <Form.Control type="text" placeholder={t('addPerson.additionalStudies')} 
                            value={servantInfoState.additional_studies} onChange={(e)=> handleServantInfo('additional_studies',e.target.value)}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6} className="mb-3">
                    <Form.Group>
                        <Form.Label>{t('addPerson.gift')}</Form.Label>
                        <Form.Control type="text" placeholder={t('addPerson.gift')} 
                            value={servantInfoState.special_gifts} onChange={(e)=> handleServantInfo('special_gifts',e.target.value)}
                        />
                    </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                    <Form.Group>
                        <Form.Label>{t('addPerson.gradClassRep')}</Form.Label>
                        <Form.Control type="text" placeholder={t('addPerson.gradClassRep')} 
                            value={servantInfoState.graduation_class_reps} onChange={(e)=> handleServantInfo('graduation_class_reps',e.target.value)}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6} className="mb-3">
                    <Form.Group>
                        <Form.Label>{t('addPerson.addServicesOut')}</Form.Label>
                        <Form.Control type="text" placeholder={t('addPerson.addServicesOut')} 
                            value={servantInfoState.additional_services_out} onChange={(e)=> handleServantInfo('additional_services_out',e.target.value)}
                        />
                    </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                    <Form.Group>
                        <Form.Label>{t('addPerson.gradYear')}</Form.Label>
                        <Datetime
                            timeFormat={false}
                            onChange={(e)=>handleServantInfo("servant_graduation_year", e)}
                            renderInput={(props, openCalendar,closeCalendar) => (
                                <InputGroup>
                                <InputGroup.Text onClick={openCalendar}><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    value={servantInfoState.servant_graduation_year}
                                    placeholder="YYYY/MM/DD"
                                    onFocus={openCalendar}
                                    onBlur={closeCalendar}
                                />
                            </InputGroup>
                        )} />
                    </Form.Group>
                </Col>
            </Row>
        </div>
    );
}
 
export default ServantInfo;