import {get,post,deleteRequest,update} from "./index";

export const getAllQualifications = async () =>{
    const response = await get("/qualifications");
    return response;
}
export const createNewQualification = async(name) =>{
    const response = await post("/qualifications/create",{"name":name});
    return response;
}
export const deleteAQualification = async(id) => {
    const response = await deleteRequest("/qualifications/delete",{"id":id});
    return response;
}
export const updateAQualification = async({name,id}) =>{
    const response = await update("/qualifications/update",{"id":id,"name":name});
    return response;
}