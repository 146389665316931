import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import LanguageDetector from "i18next-browser-languagedetector";
import translationEng from "./en.json";
import translationAr from "./ar.json";

const DETECTION_OPTIONS = {
    order: ['localStorage','navigator'],
    caches: ['localStorage']
};
const Lang = localStorage.getItem("i18nnextLng") || "ar";
const dir = (Lang === 'en')? 'ltr':'rtl';
document.documentElement.dir = dir;

if(!localStorage.getItem('Lang')){
    if(dir==="rtl")  localStorage.setItem('Lang','ar')
    else localStorage.setItem('Lang','en')
}
i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug:true,
        load: 'languageOnly',
        detection: DETECTION_OPTIONS,
        lng: Lang,
        fallback: "ar",
        keySeparator: false,
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        // Backend:{
        //     load:`../../public/Lang/${Lang}.json`
        // }
        resources: {
            en: {
                translations: translationEng
            },
            ar: {
                translations: translationAr
            },
        },
        // // have a common namespace used around the full app
        ns: ["translations"],
        defaultNS: "translations"
    });

export default i18n;