import React, { useState } from "react";
import SimpleBar from 'simplebar-react';
import { useLocation } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes,  faPlus, faUserDoctor, 
  faStamp,faChartArea,faStreetView,faBusSimple, faChurch, faPeopleGroup, faEye } from "@fortawesome/free-solid-svg-icons";
import { Nav, Badge, Image, Button, Navbar } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import Routes from "../routes";
import { useTranslation } from 'react-i18next';
import Logo from "assets/img/logo.jpg";

export default (props = {}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";
  const onCollapse = () => setShow(!show);

  const CollapsableNavItem = (props) => {
    const [isClicked,setIsClicked] = useState(false);
    const { title, icon, children = null } = props;
    return (
      <div className="nav-item">
        <div className={`${isClicked?'clicked':''} accordion-item`}>
          <button aria-expanded="true" href="#" className={`d-flex justify-content-between align-items-center accordion-button nav-link`} onClick={()=>setIsClicked(!isClicked)}>
            <span>
              <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /></span>
              <span className="sidebar-text mx-1">{title}</span>
            </span>
          </button>
          {isClicked? 
            <Nav className="flex-column">
              {children}
            </Nav>        
            :
            null
          }
        </div>
    </div>
    );
  };

  const NavItem = (props) => {
    const { title, link, external, target, icon, image, badgeText, badgeBg = "secondary", badgeColor = "primary" } = props;
    const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between" : "";
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span> : null}
            {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}

            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? (
            <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <Navbar expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-md-none">

        <Navbar.Brand className="me-lg-5" as={Link} to={Routes.getPeople.path}>
          <Image src={Logo} className="card-img-top rounded-circle border-white" />
        </Navbar.Brand>
        <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar className={`collapse ${showClass} sidebar d-md-block bg-dark text-white`}>
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">
                  <Image src={Logo} className="card-img-top rounded-circle border-white" />
                </div>
                <div className="d-block">
                  <h6>{t('nav.name')}</h6>
                </div>
              </div>
              <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
              <NavItem title={t('routing.Jobs')} link={Routes.Jobs.path} icon={faUserDoctor} />
              <NavItem title={t('routing.Qualifications')} link={Routes.Qualifications.path} icon={faStamp} />
              <NavItem title={t('routing.Areas')} link={Routes.Areas.path} icon={faChartArea} />
              <NavItem title={t('routing.Streets')} link={Routes.Streets.path} icon={faStreetView} />
              <NavItem title={t('routing.Studies')} link={Routes.Studies.path} icon={faBusSimple} />
              <NavItem title={t('routing.Churches')} link={Routes.Churches.path} icon={faChurch} />
              <CollapsableNavItem eventKey={t('routing.People')} title={t('routing.People')} icon={faPeopleGroup}>
                <NavItem title={t('routing.AddPerson')} link={Routes.addPerson.path} icon={faPlus}/>
                <NavItem title={t('routing.People')} link={Routes.getPeople.path} icon={faEye}/>
              </CollapsableNavItem>
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
