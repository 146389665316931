import { useState, useLayoutEffect,useReducer} from "react";
import {
  getAllChurches,
  createNewChurch,
  deleteAChurch,
  updateAChurch
} from "services/churchService";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';

const useChurchCRUD = () => {
  const { t } = useTranslation();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [churches, setChurches] = useState([]);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [clickLoading, setClickLoading] = useState(false);
  const [updatedChurch, setUpdatedChurch] = useState({});
  
  const initialChurchState = {
    name:"",
  };
  useLayoutEffect(() => {
    fetchData();
  }, []);
  const reducer = (state,action) =>{
    switch (action.type) {
      case "SET_INPUT":
        return {
          ...state,
          [action.field]:action.payload
        }
        case "RESET":
          return {
            ...initialChurchState
          }
          default: 
          return state;
        }
      }
      const [newChurchState, dispatch] = useReducer(reducer, initialChurchState);
      const fetchData = async () => {
        const response = await getAllChurches();
    if (response.status === 200) {
      setChurches(response?.data?.data);
    }
  };
  const handleCreateModalClose = () => {
    setShowCreateModal(false);
    setClickLoading(false);
  }
  const onOpenCreateModal = () => {
    setShowCreateModal(true);
    setClickLoading(false);
  };
  const handleInputField = (e) => {
    dispatch({
      type: "SET_INPUT",
      field : e.target.name,
      payload : e.target.value
    }) 
  }
  
  const addChurch = async () => {
    setClickLoading(true);
    const response = await createNewChurch(newChurchState);
    if(response.data.success){
      toast.success(t('ProcesSucceeded'), {
        position: 'top-left',
        autoClose: true,
        icon: "✅"
      });
      handleCreateModalClose();
      fetchData();
      dispatch({type: "RESET"})
    }
    else{
        toast.error(response.data.message || t('ProcessFailed'), {
            position: 'top-left',
            autoClose: true,
            progress:0.2
          });
        }
  };
  const deleteHandler = async (id) => {
    const response = await deleteAChurch(id);
    fetchData();
  };
  const handleUpdateModalClose = () => {
    setShowUpdateModal(false);
    setClickLoading(false);

  }
  const onOpenUpdateModal = (Church) => {
    setUpdatedChurch(Church);
    setShowUpdateModal(true);
    setClickLoading(false);

  };
  const OnChangeHandler = (e) => {
    let churchName = e.target.value;
    setUpdatedChurch({...updatedChurch,name:churchName});
  };
  const sendUpdateRequest = async () => {
    setClickLoading(true);
    const response = await updateAChurch(updatedChurch);
    if(response?.data?.success){
        toast.success(t('ProcesSucceeded'), {
            position: 'top-left',
            autoClose: true,
            icon: "✅"
        });
        handleUpdateModalClose();
        fetchData();
        setUpdatedChurch({});
    }
    else{
        toast.error(t('ProcessFailed'), {
            position: 'top-left',
            autoClose: true,
            progress:0.2
        });
    }
  };
  return {
    onOpenCreateModal,
    showCreateModal,
    setShowCreateModal,
    handleCreateModalClose,
    newChurchState,
    handleInputField,
    addChurch,
    churches,
    deleteHandler,
    onOpenUpdateModal,
    handleUpdateModalClose,
    showUpdateModal,
    updatedChurch,
    OnChangeHandler,
    sendUpdateRequest,
    clickLoading

  };
};
export default useChurchCRUD;
