import React from "react";
import {
  Modal
} from "@themesberg/react-bootstrap";
import useJobCRUD from "customHooks/useJobCRUD";
import { useTranslation } from 'react-i18next';
import STGButton from "components/STGButton";

const Jobs = () => {
  const {
    jobs,
    onOpenUpdateModal,
    deleteHandler,
    onOpenCreateModal,
    showCreateJobModal,
    handleCreateModalClose,
    setNewJob,
    newJob,
    showUpdateJobModal,
    handleUpdateModalClose,
    updatedJobName,
    OnChangeUpdateJobHandler,
    sendUpdateRequest,
    addJob,
    clickLoading
  } = useJobCRUD();
  const { t } = useTranslation();
  
  return (
    <>
      <div className="container px-0">
        <section>
          <div className="d-flex align-items-start">
            <h2>{t('Jobs.listofjobs')}</h2>
            <button className="btn btn-primary btn-sm jobs__add-button" onClick={onOpenCreateModal}>
              {t('Jobs.add')}
            </button>
          </div>
          <hr />
          <div className="row" style={{overflowY: "hidden"}}>
           {jobs.length>0 ?
           <div className="table-responsive">
              <table className="w-75 table table-striped table-bordered table-hover">
                <thead className="px-0 bg-info">
                  <tr>
                    <th className="">
                      <h5>{t('Jobs.name')} </h5>
                    </th>
                    <th className="">
                      <h5>{t('Jobs.Actions')} </h5>
                    </th>
                  </tr>
                  <tr>
                  </tr>
                </thead>
                <tbody>
                  {jobs.map((job, index) => (
                      <tr key={index}>
                        <td className="">
                            <span className="h6">{job.name}</span>
                          <div>
                          </div>
                        </td>
                        <td className="">
                            <button  className="btn btn-info"  onClick={()=>onOpenUpdateModal(job)} >
                            {t('Jobs.update')}
                            </button>
                            <button className="mx-2 btn btn-danger"  onClick={()=>deleteHandler(job.id)} >
                            {t('Jobs.delete')}
                            </button>
                        </td>
                      </tr>
                  ))}
                </tbody>
              </table>
           </div>
            :
            <h2 className="text-danger">{t('Jobs.Noitems')}</h2>
           } 
          </div>
        </section>

        {/* Add new job modal  */}
        <section>
          
          <Modal
            as={Modal.Dialog}
            centered
            show={showCreateJobModal}
            onHide={handleCreateModalClose}
          >
            <Modal.Header>
              <Modal.Title className="h6">{t('Jobs.add')}</Modal.Title>
              <button
                type="button" className="btn btn-close modalCloseButton"
                aria-label="Close"
                onClick={handleCreateModalClose}
              />
            </Modal.Header>
            <Modal.Body>
             
              <div className="col-lg-8 col-md-12">
                <form id="jobName">
                  <label className="form-label">{t('Jobs.name')}</label>
                  <input
                    required
                    className="form-control"
                    type="text"
                    placeholder={t('Jobs.enter')}
                    value={newJob}
                    onChange={(e)=>setNewJob(e.target.value)}
                  />
                </form>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <STGButton classes="w-100 m-auto" loading={clickLoading} t={t} onCLickHandler={addJob} disabled={!newJob}>
                {t('Jobs.add')}
              </STGButton>
            </Modal.Footer>
          </Modal>
        </section>

        {/* Update new Job modal  */}
        <section>
          <Modal
            as={Modal.Dialog}
            centered
            show={showUpdateJobModal}
            onHide={handleUpdateModalClose}
          >
            <Modal.Header>
              <Modal.Title className="h6">{t('Jobs.updateCurrent')}</Modal.Title>
              <button
                aria-label="Close"
                type="button" className="btn btn-close modalCloseButton"
                onClick={handleUpdateModalClose}
              />
            </Modal.Header>
            <Modal.Body>
             
              <div className="col-lg-8 col-md-12">
                <form id="jobNameUpdated">
                  <label className="form-label">{t('Jobs.JobName')}</label>
                  <input
                    required
                    className="form-control"
                    type="text"
                    placeholder={t('Jobs.enter')}
                    value={updatedJobName}
                    onChange={OnChangeUpdateJobHandler}
                  />
                </form>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <STGButton classes="w-100 m-auto" loading={clickLoading} t={t} onCLickHandler={sendUpdateRequest} disabled={!updatedJobName}>
                {t('Jobs.updateCurrent')}
              </STGButton>
            </Modal.Footer>
          </Modal>
        </section>
      </div>
    </>
  );
};
export default Jobs;