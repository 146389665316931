
import { useLocation } from 'react-router-dom';


export const usePersonDetails = (t) =>{
    const {state} = useLocation();
    let person = state?.person;
    const retrievePhones = () =>{
        if(person?.phones){
            let phones = [];
            for(let i = 0; i < person.phones.length; i++){
                phones.push(person.phones[i].phone);
            }
            return phones
        }
        return [];
    }
    const booleanValues = [t("no"), t("yes")];
    const getValue = (value, isBoolean=false) => {
        if(isBoolean) return booleanValues[value] || "";
        return (value) ? value : "";
    }
    const retrieveDataSection = (title, value, isBoolean=false) => {
        return (
            <div className="field-wrapper">
                <div className="heading-field">{title} </div>
                {value == "phones" ? 
                    retrievePhones()?.map(el =>{
                        return(
                            <div className="data-field"> {getValue(el, false)} </div>
                        )
                    })
                    :
                    <div className="data-field"> {getValue(value, isBoolean)} </div>
                }
            </div>
        )        
    }
    const retrieveAddressDetails = (address)=>{
        if(address){
            return `${address?.street_id}  - ${address?.building_number} - ${address?.floor_number} - ${address?.apartment_number}
            - ${address?.description} - ${address?.map}`
        }
        return "";
    }
    const retrievePersonBrothers = (brothers) =>{
        if(brothers){
            return brothers.map((brother,index)=>{
                return (`${brother.full_name} ${index!== brothers.length-1 ? '،' :''}`)
            })
        }
        return "";
    }
    return {
        person,
        retrieveDataSection,
        retrieveAddressDetails,
        retrievePersonBrothers,
        retrievePhones
    }
}
export default usePersonDetails;