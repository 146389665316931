import React, { useState, useLayoutEffect } from "react";
import {
  getAllQualifications,
  createNewQualification,
  deleteAQualification,
  updateAQualification,
} from "services/qualificationService";

const useQualificationCRUD = () => {
  const [showCreateQualificationModal, setShowCreateQualificationModal] = useState(false);
  const [showUpdateQualificationModal, setShowUpdateQualificationModal] = useState(false);
  const [qualifications, setQualifications] = useState([]);
  const [newQualification, setNewQualification] = useState("");
  const [updatedQualification, setUpdatedQualification] = useState({});
  const [updatedQualificationName, setUpdatedQualificationName] = useState("");
  const [clickLoading, setClickLoading] = useState(false);
  useLayoutEffect(() => {
    fetchData();
  }, []);
  const handleCreateModalClose = () => {
    setShowCreateQualificationModal(false);
    setClickLoading(false);
  }
  const handleUpdateModalClose = () => {
    setShowUpdateQualificationModal(false);
    setClickLoading(false);
  }
  const onOpenCreateModal = () => {
    setShowCreateQualificationModal(true);
    setClickLoading(false);
  };
  const onOpenUpdateModal = (qualification) => {
    setUpdatedQualification(qualification);
    setUpdatedQualificationName(qualification.name);
    setShowUpdateQualificationModal(true);
    setClickLoading(false);
  };
  const addQualification = async () => {
    setClickLoading(true);
    const response = await createNewQualification(newQualification);
    handleCreateModalClose();
    fetchData();
    setNewQualification("");
  };
  const sendUpdateRequest = async () => {
    setClickLoading(true);
    const response = await updateAQualification({ ...updatedQualification, name: updatedQualificationName });
    handleUpdateModalClose();
    fetchData();
    setUpdatedQualification({});
    setUpdatedQualificationName("");
  };
  const deleteHandler = async (id) => {
    const response = await deleteAQualification(id);
    fetchData();
  };
  const fetchData = async () => {
    const response = await getAllQualifications();
    if (response.status === 200) {
      setQualifications(response.data.data);
    }
  };
  const OnChangeUpdateQualificationHandler = (e) => {
    let tempQualificationName = e.target.value;
    setUpdatedQualificationName(tempQualificationName);
  };

  return {
    qualifications,
    onOpenUpdateModal,
    deleteHandler,
    onOpenCreateModal,
    showCreateQualificationModal,
    handleCreateModalClose,
    setNewQualification,
    newQualification,
    showUpdateQualificationModal,
    handleUpdateModalClose,
    updatedQualificationName,
    OnChangeUpdateQualificationHandler,
    sendUpdateRequest,
    addQualification,
    clickLoading
  };
};
export default useQualificationCRUD;
