import { useState, useEffect } from "react";
import { landLineRegex, phoneRegex } from "utils/regex";

const useSTGPhone = (initialPhones, onChangeHandler, handlePhoneError) => {
    const [phones, setPhones] = useState(initialPhones);
    const [errors, setErrors] = useState(new Array(initialPhones.length).fill(0).map(i => {return{hasError : false}}));
    const hasError =  errors.some(el => el.hasError);
    const addPhoneInput = () => {
        if(phones.length < 5){
            const tempPhones = [...phones];
            const tempErrors = [...errors];
            tempPhones.push({
                "phone_prefix": "",
                "phone": ""
            })
            tempErrors.push({hasError : false});
            setPhones(tempPhones);
            setErrors(tempErrors);
        }
    };
    const changePhoneInput = (key, value, idx) => {
        const tempPhones = [...phones];
        tempPhones[idx][key] = value;
        const tempErrors = [...errors];
        tempErrors[idx].hasError = (value !== "") ? (!landLineRegex.test(value) && !phoneRegex.test(value)) : false;
        setPhones(tempPhones);
        setErrors(tempErrors);
        handlePhoneError(tempErrors.some(el => el.hasError))
        onChangeHandler(tempPhones);
    }
    useEffect(() => {
        setPhones(initialPhones);
    }, [initialPhones])
    
    return {
        phones,
        addPhoneInput,
        changePhoneInput,
        errors,
        hasError
    };
}
 
export default useSTGPhone;