import { useState, useEffect} from 'react';
import {retrieveStudyName} from "utils/peopleutil";
import { getAllChurches, createNewChurch} from "services/churchService";
import { getAllJobs, createNewJob} from "services/jobService";
import { getAllQualifications, createNewQualification} from "services/qualificationService";
import {getAllStudies , createNewStudy} from "services/studyService";
import { toast } from 'react-toastify';

const useAdditionalInfo = (t, handleAdditionalInfo) => {
    //states
    const [churches,setChurches] = useState([]);
    const [churchName,setChurchName] = useState("");
    const [disabledAddChurch , setDisabledAddChurch] = useState(false);
    const [jobs,setJobs] = useState([]);
    const [jobName,setJobName] = useState("");
    const [disabledAddjob , setDisabledAddjob] = useState(false);
    const [qualifications,setQualifications] = useState([]);
    const [qualificationName,setQualificationName] = useState("");
    const [disabledAddQualification , setDisabledAddQualification] = useState(false);
    const [studies,setStudies] = useState([]);
    const [study,setStudy] = useState({name: "",parentId:null});
    const [disabledAddStudy , setDisabledAddStudy] = useState(false);

    //methods 
      const fetchChurches = async () => {
        const response = await getAllChurches();
        if (response.status === 200) {
          setChurches(response?.data?.data);
        }
      };
      const fetchJobs = async () => {
        const response = await getAllJobs();
        if (response.status === 200) {
          setJobs(response?.data?.data);
        }
      };
      const fetchQualifications = async () => {
        const response = await getAllQualifications();
        if (response.status === 200) {
          setQualifications(response?.data?.data);
        }
      };
      const fetchStudies = async () => {
        const response = await getAllStudies();
        if (response.status === 200) {
          const sortedItems = response?.data?.data;
          for (let index = 0; index < sortedItems.length; index++) {
            sortedItems[index].name = retrieveStudyName(sortedItems[index]) ;
          }
          setStudies(sortedItems);
        }
      };
      const addChurch = async () => {
        const response = await createNewChurch({name:churchName});
        if(response.data.success){
            toast.success(t('ProcesSucceeded'), {
                position: 'top-left',
                autoClose: true,
                icon: "✅"
            });
            handleAdditionalInfo('church_id', response.data.data.id)
            fetchChurches();
            setChurchName("");
            setDisabledAddChurch(true);
        }
        else{
            toast.error(response.data.message || t('ProcessFailed'), {
                position: 'top-left',
                autoClose: true,
                progress:0.2
            });
        }
      };
      const addJob = async () => {
        const response = await createNewJob(jobName);
        if(response.data.success){
            toast.success(t('ProcesSucceeded'), {
                position: 'top-left',
                autoClose: true,
                icon: "✅"
            });
            handleAdditionalInfo('job_id', response.data.data.id)
            fetchJobs();
            setJobName("");
            setDisabledAddjob(true);
        }
        else{
            toast.error(response.data.message || t('ProcessFailed'), {
                position: 'top-left',
                autoClose: true,
                progress:0.2
            });
        }
      };
      const addQualification = async () => {
        const response = await createNewQualification(qualificationName);
        if(response.data.success){
            toast.success(t('ProcesSucceeded'), {
                position: 'top-left',
                autoClose: true,
                icon: "✅"
            });
            handleAdditionalInfo('qualification_id', response.data.data.id)
            fetchQualifications();
            setQualificationName("");
            setDisabledAddQualification(true);
        }
        else{
            toast.error(response.data.message || t('ProcessFailed'), {
                position : 'top-left',
                autoClose : true,
                progress : 0.2
            });
        }
      };
      const addٍStudy = async () => {
        const response = await createNewStudy(study.name,study.parentId);
        if(response.data.success){
            toast.success(t('ProcesSucceeded'), {
                position: 'top-left',
                autoClose: true,
                icon: "✅"
            });
            handleAdditionalInfo('study_id', response.data.data.id)
            fetchStudies();
            setStudy({name: "", parentId:null});
            setDisabledAddStudy(true);
        }
        else{
            toast.error(response.data.message || t('ProcessFailed'), {
                position: 'top-left',
                autoClose: true,
                progress:0.2
            });
        }
      };

      //constants
      const maritalStatusOptions = [
        {name:t('addPerson.maritalStatus'),value:""},
        {name:t('addPerson.single'),value:"أعزب"},
        {name:t('addPerson.married'),value:"متزوج"},
        {name:t('addPerson.broken'),value:"منفصل"},
        {name:t('addPerson.divorced'),value:"مطلق"}
      ]
      const regularityOptions = [
        {name:t('addPerson.regularity'),value:""},
        {name:t('addPerson.regular'),value:"منتظم"},
        {name:t('addPerson.rare'),value:"غير منتظم"}
      ]
      const yesOrNoOptions = [
        {name:t('dontKnow'),value:NaN},
        {name:t('yes'),value:1},
        {name:t('no'),value: 0}
      ]
      const churchOptions = [{id:NaN,name:t('Choose')},...churches];
      const jobOptions = [{id:NaN,name:t('Choose')},...jobs];
      const qualificationOptions = [{id:NaN,name:t('Choose')},...qualifications];
      const studyOptions = [{id:NaN,name:t('Choose')},...studies];

    // Effects
    useEffect(() => {
      fetchChurches();
      fetchJobs();
      fetchQualifications();
      fetchStudies();
     }, []);
    return {
        maritalStatusOptions, 
        regularityOptions, yesOrNoOptions,
        churchName,
        setChurchName,
        addChurch,
        disabledAddChurch,
    
        jobName,
        setJobName,
        addJob,
        disabledAddjob,
    
        qualificationName,
        setQualificationName,
        disabledAddQualification,
        addQualification, 
    
        study,
        setStudy,
        addٍStudy,
        disabledAddStudy,
        churchOptions,
        jobOptions,
        qualificationOptions,
        studyOptions
    }
}
export default useAdditionalInfo;