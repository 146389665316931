import {Form} from "@themesberg/react-bootstrap";
import useSTGPhone from "./useSTGPhone";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const STGPhone = ({onChangeHandler, initialValue, handlePhoneError}) => {
    const { t } = useTranslation();
    const { phones, addPhoneInput, changePhoneInput, errors, hasError } = useSTGPhone(initialValue, onChangeHandler, handlePhoneError);
    return ( 
        <>
        <Form.Group id="phone" className="stg-phone">
            <Form.Label>{t('addPerson.addPhone')}</Form.Label>
            <span>{t('phones.rightFormat')}</span>
            {phones.map((phone,idx) =>{
                return(
                    <div key={idx} className={`phone-container ${(errors[idx] && errors[idx]?.hasError) ? 'error-field' : ''}`} >
                        <Form.Control type="tel" placeholder={t('addPerson.addPhone')} name='phone' value={phone.phone} onChange={(e)=>changePhoneInput('phone', e.target.value,idx)}/>
                        {(phones.length <5 && idx === phones.length-1) ?
                            <button className="plus-phone" onClick={addPhoneInput}>
                                <FontAwesomeIcon icon={faPlus} />
                            </button>
                        :null}
                    </div>
                )
            })}
            {hasError && 
                <div className="error-message">
                    <ul>
                        <li>{t('validation')}</li>
                    </ul>
                </div>
            }
        </Form.Group>
        </>
     );
}
 
export default STGPhone;