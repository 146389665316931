import { useState, useEffect} from 'react';
import { getAllPeople } from 'services/peopleService';

const useFamilyInfo = (t) => {
    //states
    const [people,setPeople] = useState([]);

    //methods 
      const fetchPeople = async () => {
        const response = await getAllPeople();
        if (response.status === 200) {
          setPeople(response?.data?.data);
        }
      };
    //constants
    const peopleOptions = [{id:NaN,full_name:t('Choose')}, ...people];

    // Effects
    useEffect(() => {
      fetchPeople();
     }, []);
    return { people, peopleOptions}
}
export default useFamilyInfo;