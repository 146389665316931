import React from "react";
import {
  Modal,
} from "@themesberg/react-bootstrap";
import useStreetCRUD from "customHooks/useStreetCRUD";
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
import {Dropdown,DropdownButton,Accordion} from 'react-bootstrap';
import STGButton from "components/STGButton";

const Streets = () => {
  const {
    streets,
    onOpenUpdateModal,
    deleteHandler,
    onOpenCreateModal,
    showCreateStreetModal,
    handleCreateModalClose,
    showUpdateStreetModal,
    handleUpdateModalClose,
    updatedStreetName,
    sendUpdateRequest,
    addStreet,
    streetState,
    areas,
    handleNewStreetNameChange,
    onChangeArea,
    area,
    setArea,
    addArea,
    onChangeUpdatedArea,
    handleUpdatedStreetNameChange,
    updatedStreetState,
    clickLoading
  } = useStreetCRUD();

  const { t } = useTranslation();
  
  return (
    <>
      <div className="container px-0 py-2">
        <section>
        <div className="d-flex align-items-start">
          <h2>{t('Streets.listOfstreets')}</h2>
          <button className="btn btn-primary btn-sm jobs__add-button" onClick={onOpenCreateModal}>
              {t('Streets.add')}
            </button>
        </div>
          <hr />
          <div className="row" style={{overflowY: "hidden"}}>
           {streets.length>0 ?
           <div className="table-responsive">
              <table className="w-75 table table-striped table-bordered table-hover">
                <thead className="px-0 bg-info">
                  <tr>
                    <th className="">
                      <h5>{t('Streets.name')} </h5>
                    </th>
                    <th className="">
                      <h5>{t('Streets.areaName')} </h5>
                    </th>
                    <th className="">
                      <h5>{t('Streets.Actions')} </h5>
                    </th>
                  </tr>
                  <tr>
                  </tr>
                </thead>
                <tbody>
                  {streets.map((street, index) => (
                      <tr key={index}>
                        <td className="">
                            <span className="h6">{street.name}</span>
                        </td>
                        <td className="">
                            <span className="h6">{street?.area?.name}</span>
                        </td>
                        <td className="">
                            <button  className="btn btn-primary"  onClick={()=>onOpenUpdateModal(street)} >
                            {t('Streets.update')}
                            </button>
                            <button className="mx-2 btn btn-danger"  onClick={()=>deleteHandler(street.id)} >
                            {t('Streets.delete')}
                            </button>
                        </td>
                      </tr>
                  ))}
                </tbody>
              </table>
           </div>
            :
            <h2 className="text-danger">{t('Streets.Noitems')}</h2>
           } 
          </div>
        </section>

        {/* Add new street modal  */}
        <section>   
          <Modal
            as={Modal.Dialog}
            centered
            show={showCreateStreetModal}
            onHide={handleCreateModalClose}
          >
            <Modal.Header>
              <Modal.Title className="h6">{t('Streets.add')}</Modal.Title>
              <button
                type="button" className="btn btn-close modalCloseButton"
                aria-label="Close"
                onClick={handleCreateModalClose}
              />
            </Modal.Header>
            <Modal.Body>
             
              <div className="">
                <form id="streetName" className="d-flex justify-content-between">
                  <div>
                    <label className="form-label">{t('Streets.name')}</label>
                    <input
                      required
                      className="form-control"
                      type="text"
                      placeholder={t('Streets.enter')}
                      value={streetState.street}
                      name="street"
                      onChange={handleNewStreetNameChange}
                    />
                  </div>
                  <div>
                    <label className="form-label">{t('Streets.areaName')}</label>
                    <DropdownButton id="dropdown-basic-button" title={streetState.area.name?streetState.area.name:t('Streets.chooseArea')} value={streetState.area.name}>
                      {(areas && areas.length>0) && areas?.map((area,idx)=>{
                        return(
                          <Dropdown.Item onClick={()=>onChangeArea(area)} value={area.name} key={idx}>{area.name}</Dropdown.Item>

                        )
                      })}
                      {(!areas || areas.length===0 || !Array.isArray(areas)) && <Dropdown.Item value={''}>{t('Studies.Noitems')}</Dropdown.Item>}
                    </DropdownButton>
                  </div>
                </form>
               
                <Accordion className="mt-3">
                  <Accordion.Item eventKey="1">
                    <Accordion.Header className="bg-warning">{t("Streets.addArea")}</Accordion.Header>
                    <Accordion.Body className="d-flex justify-content-space-between mt-1 mx-1">
                      <input
                          required
                          className="form-control streets__area-input"
                          type="text"
                          maxLength={'40'}
                          placeholder={t('Areas.enter')}
                          value={area}
                          onChange={(e)=>setArea(e.target.value)}
                        />
                        <STGButton classes="m-auto" loading={clickLoading} t={t} onCLickHandler={()=>addArea()} disabled={!area}>
                            {t('Areas.add')}
                        </STGButton>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <STGButton classes="w-100 m-auto" loading={clickLoading} t={t} onCLickHandler={addStreet} disabled={!streetState.street}>
                {t('Streets.add')}
              </STGButton>
            </Modal.Footer>
          </Modal>
        </section>

        {/* Update new Street modal  */}
        <section>
          
          <Modal
            as={Modal.Dialog}
            centered
            show={showUpdateStreetModal}
            onHide={handleUpdateModalClose}
          >
            <Modal.Header>
              <Modal.Title className="h6">{t('Streets.updateCurrent')}</Modal.Title>
              <button
                type="button" className="btn btn-close modalCloseButton"
                aria-label="Close"
                onClick={handleUpdateModalClose}
              />
            </Modal.Header>
            <Modal.Body>
              <div className="">
                <form id="streetName" className="d-flex justify-content-between">
                  <div>
                    <label className="form-label">{t('Streets.name')}</label>
                    <input
                      required
                      className="form-control"
                      type="text"
                      placeholder={t('Streets.enter')}
                      value={updatedStreetState.street}
                      name="street"
                      onChange={handleUpdatedStreetNameChange}
                    />
                  </div>
                  <div>
                    <label className="form-label">{t('Streets.areaName')}</label>
                    <DropdownButton id="dropdown-basic-button" title={updatedStreetState.area.name?updatedStreetState.area.name:t('Streets.chooseArea')} value={updatedStreetState.area.name}>
                      {(areas && areas.length>0) && areas?.map((area,idx)=>{
                        return(
                          <Dropdown.Item onClick={()=>onChangeUpdatedArea(area)} value={area.name} key={idx}>{area.name}</Dropdown.Item>

                        )
                      })}
                      {(!areas || areas.length===0 || !Array.isArray(areas)) && <Dropdown.Item value={''}>{t('Studies.Noitems')}</Dropdown.Item>}
                    </DropdownButton>
                  </div>
                </form>
               
                <Accordion className="mt-3">
                  <Accordion.Item eventKey="1">
                    <Accordion.Header className="bg-warning">{t("Streets.addArea")}</Accordion.Header>
                    <Accordion.Body className="d-flex justify-content-space-between mt-1 mx-1">
                      <input
                          required
                          className="form-control streets__area-input"
                          type="text"
                          maxLength={'40'}
                          placeholder={t('Areas.enter')}
                          value={area}
                          onChange={(e)=>setArea(e.target.value)}
                        />
                         <STGButton classes="m-auto" loading={clickLoading} t={t} onCLickHandler={()=>addArea(true)} disabled={!area}>
                            {t('Areas.add')}
                          </STGButton>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <STGButton classes="w-100 m-auto" loading={clickLoading} t={t} onCLickHandler={sendUpdateRequest} disabled={!updatedStreetState.street}>
                {t('Streets.updateCurrent')}
              </STGButton>
            </Modal.Footer>
          </Modal>
        </section>
        <ToastContainer />
      </div>
    </>
  );
};
export default Streets;