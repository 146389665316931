import React, {useContext} from 'react';
import { HashRouter } from "react-router-dom";
import { Route, Routes, Navigate } from "react-router-dom";
import MyRoutes  from "../routes";
import RouteWithSidebar from "components/RouteWithSidebar";
// pages
import Jobs from "./Jobs";
import Qualifications from "./Qualifications";
import Areas from "./Areas";
import Streets from "./Streets";
import Churches from "./Churches";
import Studies from "./Studies";
import AddPerson from "./people/AddPerson";
import PeopleList from "./people/PeopleList";
import PersonDetails from './people/PersonDetails';
import ScrollToTop from "components/ScrollToTop";
import Login from './Login';
import ProtectedRoute from "components/ProtectedRoute";
import useAuth from "contexts/useAuth";
import UpdatePerson from './people/UpdatePerson';

const HomePage = () => {
  const { auth } = useAuth();
  const isUserLogged = auth.isLogged;
  
  return(
    <div>
        <HashRouter>
          <ScrollToTop />
          <Routes>
                <Route path='/' element={isUserLogged ? <Navigate to={MyRoutes.getPeople.path} replace /> : <Login />} />
                <Route element={<ProtectedRoute />}>
                  <Route element={<RouteWithSidebar />}>
                    <Route path={MyRoutes.Jobs.path} element={<Jobs />}/>
                  </Route>
                  <Route element={<RouteWithSidebar />}>
                    <Route path={MyRoutes.Areas.path} element={<Areas />}/>
                  </Route>
                  <Route element={<RouteWithSidebar />}>
                    <Route path={MyRoutes.Churches.path} element={<Churches />}/>
                  </Route>
                  <Route element={<RouteWithSidebar />}>
                    <Route path={MyRoutes.Qualifications.path} element={<Qualifications />}/>
                  </Route>
                  <Route element={<RouteWithSidebar />}>
                    <Route path={MyRoutes.Streets.path} element={<Streets />}/>
                  </Route>
                  <Route element={<RouteWithSidebar />}>
                    <Route path={MyRoutes.Studies.path} element={<Studies />}/>
                  </Route>
                  <Route element={<RouteWithSidebar />}>
                    <Route path={MyRoutes.personDetail.path} element={<PersonDetails />}/>
                  </Route>
                  <Route element={<RouteWithSidebar />}>
                    <Route path={MyRoutes.personUpdate.path} element={<UpdatePerson />}/>
                  </Route>
                  <Route element={<RouteWithSidebar />}>
                    <Route path={MyRoutes.addPerson.path} element={<AddPerson />}/>
                  </Route>
                  <Route element={<RouteWithSidebar />}>
                    <Route path={MyRoutes.getPeople.path} element={<PeopleList />}/>
                  </Route>
                </Route>
            <Route path="*" element={<Navigate to={isUserLogged? MyRoutes.getPeople.path : "/"} replace/>} />
            
          </Routes>
        </HashRouter>
    </div>

  )
};
export default HomePage;
