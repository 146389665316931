import React from "react";
import {
  Col, Row, Card, Form, Button, InputGroup
} from "@themesberg/react-bootstrap";
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import {retrieveStudyName} from "utils/peopleutil";
import usePersonDetails from "customHooks/usePersonDetails";


const PersonDetails = () => {
    const { t } = useTranslation();
    const {person, retrieveDataSection, retrievePersonBrothers} = usePersonDetails(t);

    return (
        <Card className="person-details shadow-sm ">
                <Card.Body>
                    {person? 
                        <>
                            {/* main data section  */}
                            <h4 className="mb-4">{t('addPerson.generalInfo')}</h4>
                            <Row>
                                {retrieveDataSection(t('addPerson.fullName'),person.full_name)}
                                {retrieveDataSection(t('addPerson.birthday'),person.birth_date)}
                                {retrieveDataSection(t('addPerson.gender'),person.gender)}
                                {retrieveDataSection(t('addPerson.maritalStatus'),person.marital_status)}
                                {retrieveDataSection(t('addPerson.marriageDate'),person.marriage_date)}
                                {retrieveDataSection(t('addPerson.nationalId'),person.national_id)}
                                {retrieveDataSection(t('addPerson.graduationYear'),person.graduation_year)}
                                {retrieveDataSection(t('addPerson.regularity'),person.regularity)}
                            </Row>
                            <hr />
                            <Row className="mb-4">
                                {retrieveDataSection(t('addPerson.addEmail'),person.emails[0]?.email)}
                                {retrieveDataSection(t('addPerson.addPhone'),'phones')}
                                {retrieveDataSection(t('addPerson.isDied'),person.is_died,true)}
                                {retrieveDataSection(t('addPerson.isServant'),person.is_servant,true)}
                                {retrieveDataSection(t('addPerson.isPriest'),person.is_priest,true)}
                                {retrieveDataSection(t('addPerson.isPoor'),person.is_poor,true)}
                                {retrieveDataSection(t('addPerson.isSpecial'),person.is_special,true)}
                                {retrieveDataSection(t('addPerson.isServed'),person.is_served,true)}
                                {retrieveDataSection(t('addPerson.isPeople'),person.is_people,true)}
                            </Row>
                            <hr />

                            <h4 className="mb-4">{t('addPerson.withRelations')}</h4>
                            <Row>
                                {retrieveDataSection(t('Churches.name'),person?.church?.name)}
                                {retrieveDataSection(t('Jobs.name'), person?.job?.name)}
                                {retrieveDataSection(t('Qualifications.name'), person?.qualification?.name)}
                                {retrieveDataSection(t('Studies.studyName'), retrieveStudyName(person?.study))}
                            </Row>

                            {person?.addresses[0]?.street_id ? 
                                <>
                                    <hr />
                                    <h4 className="mb-4">{t('addPerson.address')}</h4>
                                    <Row>
                                        {retrieveDataSection(t('Streets.name'), person?.addresses[0].street_id)}
                                        {retrieveDataSection(t('addPerson.building'), person?.addresses[0].building_number)}
                                        {retrieveDataSection(t('addPerson.floor'), person?.addresses[0].floor_number)}
                                        {retrieveDataSection(t('addPerson.apartment'), person?.addresses[0].apartment_number)}
                                        {retrieveDataSection(t('addPerson.description'), person?.addresses[0].description)}
                                        {retrieveDataSection(t('addPerson.map'), person?.addresses[0].map)}
                                    </Row>
                                </>
                            :null
                            }
                            {person?.servant_information ? 
                                <>
                                    <hr />
                                    <h4 className="mb-4">{t('addPerson.servant')}</h4>
                                    <Row >
                                        {retrieveDataSection(t('addPerson.additionalStudies'), person?.servant_information?.additional_studies)}
                                        {retrieveDataSection(t('addPerson.gift'), person?.servant_information?.special_gifts)}
                                        {retrieveDataSection(t('addPerson.gradYear'), person?.servant_information?.servant_graduation_year)}
                                        {retrieveDataSection(t('addPerson.gradClassRep'), person?.servant_information?.graduation_class_reps)}
                                        {retrieveDataSection(t('addPerson.gradClassRep'), person?.servant_information?.graduation_class_reps)}
                                        {retrieveDataSection(t('addPerson.addServicesOut'), person?.servant_information?.additional_services_out)}
                                    </Row>
                                </>
                            :null
                            }
                            <hr />
                            <h4 className="mb-4">{t('addPerson.oneFamily')}</h4>
                            <Row>
                                {retrieveDataSection(t('addPerson.fatherName'), person?.father?.full_name)}
                                {retrieveDataSection(t('addPerson.motherName'), person?.mother?.full_name)}
                                {retrieveDataSection(t('addPerson.partnerName'), person?.partner?.full_name)}
                                {retrieveDataSection(t('addPerson.brothersIds'), retrievePersonBrothers(person.person_brothers))}
                            </Row>
                            
                        </>
                        : 
                        <h4 >{t("Churches.Noitems")}</h4>
                    }
                    


                </Card.Body>
        </Card>
    )
};
export default PersonDetails;